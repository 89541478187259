var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "content-header row" }, [
      _c("div", { staticClass: "content-header-left col-md-9 col-12 mb-2" }, [
        _c("div", { staticClass: "row breadcrumbs-top" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("h2", { staticClass: "content-header-title float-left mb-0" }, [
              _vm._v(
                _vm._s(_vm.trans("beneficiary.beneficiary")) +
                  "\n                     "
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "breadcrumb-wrapper col-12" }, [
              _c("ol", { staticClass: "breadcrumb" }, [
                _c(
                  "li",
                  { staticClass: "breadcrumb-item" },
                  [
                    _c("router-link", { attrs: { to: "/home" } }, [
                      _vm._v(
                        "\n                                     " +
                          _vm._s(_vm.trans("general.home")) +
                          "\n                                 "
                      ),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  { staticClass: "breadcrumb-item" },
                  [
                    _c("router-link", { attrs: { to: "/home" } }, [
                      _vm._v(
                        "\n                                     " +
                          _vm._s(_vm.trans("beneficiary.enrollments")) +
                          "\n                                 "
                      ),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("li", { staticClass: "breadcrumb-item active" }, [
                  _vm._v(
                    "\n                                 " +
                      _vm._s(_vm.cohortTitle) +
                      "\n                             "
                  ),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "content-header-right text-md-right col-md-3 col-12 d-md-block d-none",
        },
        [
          _c("div", { staticClass: "form-group breadcrum-right" }, [
            !_vm.showFilterPanel && _vm.userRole == "enumerator"
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-primary btn-sm pull-right",
                    on: {
                      click: function ($event) {
                        _vm.showFilterPanel = !_vm.showFilterPanel
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "feather icon-filter" }),
                    _vm._v(" " + _vm._s(_vm.trans("general.filter"))),
                  ]
                )
              : _vm._e(),
          ]),
        ]
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "content-body" }, [
      _c("section", { attrs: { id: "basic-datatable" } }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-12" },
            [
              _c("transition", { attrs: { name: "fade" } }, [
                _vm.showFilterPanel
                  ? _c("div", { staticClass: "card border-bottom" }, [
                      _c("div", { staticClass: "card-header" }, [
                        _c("h4", { staticClass: "card-title" }, [
                          _vm._v(
                            "\n                                 " +
                              _vm._s(_vm.trans("general.filter")) +
                              "\n                             "
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "card-content" }, [
                        _c("div", { staticClass: "card-body" }, [
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-6" }, [
                              _c(
                                "div",
                                { staticClass: "form-group" },
                                [
                                  _c("label", { attrs: { for: "" } }, [
                                    _vm._v(
                                      _vm._s(_vm.trans("beneficiary.lga"))
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("v-select", {
                                    attrs: {
                                      options: _vm.lgas,
                                      reduce: (name) => name.id,
                                      label: "name",
                                      name: "lga_id",
                                    },
                                    on: { input: _vm.getSchools },
                                    model: {
                                      value: _vm.filterBeneficiaryForm.lga_id,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.filterBeneficiaryForm,
                                          "lga_id",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "filterBeneficiaryForm.lga_id",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-6" }, [
                              _c(
                                "div",
                                { staticClass: "form-group" },
                                [
                                  _c("label", { attrs: { for: "" } }, [
                                    _vm._v(_vm._s(_vm.trans("school.school"))),
                                  ]),
                                  _vm._v(" "),
                                  _c("v-select", {
                                    attrs: {
                                      options: _vm.schools,
                                      reduce: (name) => name.id,
                                      label: "name",
                                      name: "school_id",
                                    },
                                    model: {
                                      value:
                                        _vm.filterBeneficiaryForm.school_id,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.filterBeneficiaryForm,
                                          "school_id",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "filterBeneficiaryForm.school_id",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _vm.showFilterPanel
                            ? _c(
                                "button",
                                {
                                  staticClass:
                                    "btn btn-danger waves-effect waves-light pull-right mr-1 mb-1",
                                  on: {
                                    click: function ($event) {
                                      _vm.showFilterPanel = !_vm.showFilterPanel
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.trans("general.cancel")))]
                              )
                            : _vm._e(),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card-header" }, [
                  _vm.beneficiaries.total
                    ? _c(
                        "h4",
                        { staticClass: "card-title" },
                        [
                          _c("sort-by", {
                            attrs: {
                              "order-by-options": _vm.orderByOptions,
                              "sort-by": _vm.filterBeneficiaryForm.sort_by,
                              order: _vm.filterBeneficiaryForm.order,
                            },
                            on: {
                              updateSortBy: (value) => {
                                _vm.filterBeneficiaryForm.sort_by = value
                              },
                              updateOrder: (value) => {
                                _vm.filterBeneficiaryForm.order = value
                              },
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("h4", { staticClass: "card-title" }, [
                    _vm.hasPermission("approve-enrollment")
                      ? _c(
                          "button",
                          {
                            staticClass: "btn btn-success btn-sm pull-right",
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.markAsFinish()
                              },
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "feather icon-check-circle",
                            }),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.trans("beneficiary.approve_enrollment")
                                )
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "card-content" }, [
                  _c(
                    "div",
                    { staticClass: "card-body" },
                    [
                      _vm.beneficiaries.total
                        ? _c("div", { staticClass: "table-responsive" }, [
                            _c("table", { staticClass: "table" }, [
                              _c("thead", [
                                _c("tr", [
                                  _c("th", [
                                    _vm._v(
                                      _vm._s(_vm.trans("general.serial_number"))
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("th", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.trans(
                                          "beneficiary.admission_number"
                                        )
                                      )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("th", [
                                    _vm._v(
                                      _vm._s(_vm.trans("beneficiary.school"))
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("th", [
                                    _vm._v(
                                      _vm._s(_vm.trans("beneficiary.class"))
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("th", [
                                    _vm._v(
                                      _vm._s(_vm.trans("beneficiary.name"))
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("th", [
                                    _vm._v(_vm._s(_vm.trans("general.status"))),
                                  ]),
                                  _vm._v(" "),
                                  _c("th", { staticClass: "table-option" }, [
                                    _vm._v(_vm._s(_vm.trans("general.action"))),
                                  ]),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c(
                                "tbody",
                                _vm._l(
                                  _vm.beneficiaries.data,
                                  function (beneficiary, index) {
                                    return _c("tr", [
                                      _c("td", {
                                        domProps: {
                                          textContent: _vm._s(index + 1),
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("td", {
                                        domProps: {
                                          textContent: _vm._s(
                                            beneficiary.admission_number
                                          ),
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("td", {
                                        domProps: {
                                          textContent: _vm._s(
                                            beneficiary.school.name
                                          ),
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("td", {
                                        domProps: {
                                          textContent: _vm._s(
                                            beneficiary.class
                                          ),
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("td", {
                                        domProps: {
                                          textContent: _vm._s(beneficiary.name),
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("td", {
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.getStatus(beneficiary)
                                          ),
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        { staticClass: "table-option" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "btn-group" },
                                            [
                                              _c(
                                                "router-link",
                                                {
                                                  directives: [
                                                    {
                                                      name: "tooltip",
                                                      rawName: "v-tooltip",
                                                      value: _vm.trans(
                                                        "beneficiary.view_beneficiary"
                                                      ),
                                                      expression:
                                                        "trans('beneficiary.view_beneficiary')",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "btn btn-primary btn-sm",
                                                  attrs: {
                                                    to: `/beneficiaries/${beneficiary.id}`,
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "feather icon-eye",
                                                  }),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _vm.hasPermission(
                                                "enroll-beneficiaries"
                                              )
                                                ? _c(
                                                    "router-link",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "tooltip",
                                                          rawName: "v-tooltip",
                                                          value: _vm.trans(
                                                            "beneficiary.enroll_beneficiary"
                                                          ),
                                                          expression:
                                                            "trans('beneficiary.enroll_beneficiary')",
                                                        },
                                                      ],
                                                      staticClass:
                                                        "btn btn-success btn-sm",
                                                      attrs: {
                                                        to: `/beneficiaries/${beneficiary.id}/enroll`,
                                                      },
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "feather icon-user-plus",
                                                      }),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ])
                                  }
                                ),
                                0
                              ),
                            ]),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.beneficiaries.total
                        ? _c("module-info", {
                            attrs: {
                              module: "beneficiary",
                              title: "module_info_title",
                              description: "module_info_description",
                              icon: "check-circle",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c("pagination-record", {
                        attrs: {
                          "page-length": _vm.filterBeneficiaryForm.page_length,
                          records: _vm.beneficiaries,
                        },
                        on: {
                          "update:pageLength": function ($event) {
                            return _vm.$set(
                              _vm.filterBeneficiaryForm,
                              "page_length",
                              $event
                            )
                          },
                          "update:page-length": function ($event) {
                            return _vm.$set(
                              _vm.filterBeneficiaryForm,
                              "page_length",
                              $event
                            )
                          },
                          updateRecords: _vm.getBeneficiaries,
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }