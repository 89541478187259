<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-9 col-12 mb-2">
                <div class="row breadcrumbs-top">
                    <div class="col-12">
                        <h2 class="content-header-title float-left mb-0">{{trans('beneficiary.beneficiary')}}
                        </h2>
                        <div class="breadcrumb-wrapper col-12">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item">
                                    <router-link to="/home">
                                        {{ trans('general.home') }}
                                    </router-link>
                                </li>
                                <li class="breadcrumb-item">
                                    <router-link to="/home">
                                        {{ trans('beneficiary.enrollments') }}
                                    </router-link>
                                </li>
                                <li class="breadcrumb-item active">
                                    {{ cohortTitle }}
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>

            <div class="content-header-right text-md-right col-md-3 col-12 d-md-block d-none">
                <div class="form-group breadcrum-right">
                    
                    <button class="btn btn-primary btn-sm pull-right" v-if="!showFilterPanel && userRole == 'enumerator'" @click="showFilterPanel = !showFilterPanel"><i class="feather icon-filter"></i> {{trans('general.filter')}}</button>
                    
                </div>
            </div>
            

        </div>

    <div class="content-body">
        <section id="basic-datatable">
            <div class="row">
                <div class="col-12">

                    <transition name="fade">
                        <div class="card border-bottom" v-if="showFilterPanel">
                            <div class="card-header">
                                <h4 class="card-title">
                                    {{trans('general.filter')}}
                                </h4>
                            </div>
                            <div class="card-content">
                                <div class="card-body">
                                <div class="row">
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label for="">{{trans('beneficiary.lga')}}</label>
                                            <v-select @input="getSchools" :options="lgas" :reduce="name => name.id" label="name" name="lga_id" v-model="filterBeneficiaryForm.lga_id"></v-select>
                                            
                                        </div>
                                    </div>
                                                                
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label for="">{{trans('school.school')}}</label>
                                            <v-select :options="schools" :reduce="name => name.id" label="name" name="school_id" v-model="filterBeneficiaryForm.school_id"></v-select>
                                            
                                        </div>
                                    </div>
                                </div>
                                <button class="btn btn-danger waves-effect waves-light pull-right mr-1 mb-1" v-if="showFilterPanel" @click="showFilterPanel = !showFilterPanel">{{trans('general.cancel')}}</button>
                            </div>
                            </div>
                        </div>
                    </transition>
                    
                    <div class="card">
                        <div class="card-header">
                            <h4 v-if="beneficiaries.total" class="card-title">
                                <sort-by :order-by-options="orderByOptions" :sort-by="filterBeneficiaryForm.sort_by" :order="filterBeneficiaryForm.order" @updateSortBy="value => {filterBeneficiaryForm.sort_by = value}"  @updateOrder="value => {filterBeneficiaryForm.order = value}"></sort-by>
                            </h4>

                            <h4 class="card-title">
                                <button class="btn btn-success btn-sm pull-right" @click.prevent="markAsFinish()" v-if="hasPermission('approve-enrollment')"><i class="feather icon-check-circle"></i> {{trans('beneficiary.approve_enrollment')}}</button>
                            </h4>
                        </div>
                        <div class="card-content">
                            <div class="card-body">
                                <div class="table-responsive" v-if="beneficiaries.total">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th>{{trans('general.serial_number')}}</th>
                                            <th>{{trans('beneficiary.admission_number')}}</th>
                                            <th>{{trans('beneficiary.school')}}</th>
                                            <th>{{trans('beneficiary.class')}}</th>
                                            <th>{{trans('beneficiary.name')}}</th>
                                            <th>{{trans('general.status')}}</th>
                                            <th class="table-option">{{trans('general.action')}}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(beneficiary, index) in beneficiaries.data">
                                            <td v-text="index+1"></td>
                                            <td v-text="beneficiary.admission_number"></td>
                                            <td v-text="beneficiary.school.name"></td>
                                            <td v-text="beneficiary.class"></td>
                                            <td v-text="beneficiary.name"></td>
                                            <td v-html="getStatus(beneficiary)"></td>
                                            <td class="table-option">
                                                <div class="btn-group">
                                                    
                                                    <router-link :to="`/beneficiaries/${beneficiary.id}`" class="btn btn-primary btn-sm" v-tooltip="trans('beneficiary.view_beneficiary')"><i class="feather icon-eye"></i></router-link>

                                                    <router-link :to="`/beneficiaries/${beneficiary.id}/enroll`" class="btn btn-success btn-sm" v-tooltip="trans('beneficiary.enroll_beneficiary')" v-if="hasPermission('enroll-beneficiaries')"><i class="feather icon-user-plus"></i></router-link>
                                                    
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <module-info v-if="!beneficiaries.total" module="beneficiary" title="module_info_title" description="module_info_description" icon="check-circle">
                               
                            </module-info>
                            <pagination-record :page-length.sync="filterBeneficiaryForm.page_length" :records="beneficiaries" @updateRecords="getBeneficiaries"></pagination-record>
                             
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
   
   </div>
    </div>
</template>

<script>
    import sortBy from '../../components/sort-by'
    import dateRangePicker from '../../components/date-range-picker'

    export default {
        components : { sortBy,dateRangePicker },
        data() {
            return {
                userRole: '',
                beneficiaries: {
                    total: 0,
                    data: []
                },
                filterBeneficiaryForm: {
                    keyword: '',
                    start_date: '',
                    end_date: '',
                    sort_by : 'created_at',
                    order: 'desc',
                    page_length: helper.getConfig('page_length'),
                    school_id: '',
                    lga_id: ''
                },
                orderByOptions: [
                    {
                        value: 'admission_number',
                        translation: i18n.beneficiary.admission_number
                    },
                    {
                        value: 'name',
                        translation: i18n.beneficiary.name
                    },
                    {
                        value: 'email',
                        translation: i18n.beneficiary.email
                    }
                ],
                showFilterPanel: false,
                cohortTitle: '',
                lgas: [],
                schools: [],
            };
        },
        mounted(){
            if(!helper.hasPermission('list-beneficiaries')){
                helper.notAccessibleMsg();
                this.$router.push('/home');
            }

            if(!helper.featureAvailable('beneficiary')){
                helper.featureNotAvailableMsg();
                this.$router.push('/home');
            }
            this.fetchPreRequisites();
            this.getBeneficiaries();
            this.getBatchTitle();
            this.userRole = this.getRole()[0];
        },
        methods: {
            fetchPreRequisites(){
                axios.get('/api/user/pre-requisite')
                    .then(response => response.data)
                    .then(response => {
                        this.lgas = response.lgas;
                    })
                    .catch(error => {
                        helper.showDataErrorMsg(error)
                    });
            },
            getBatchTitle(){
                axios.get('/api/setting/cohort/' + this.$route.params.cohortId).then(response => response.data).then(response =>  {
                    this.cohortTitle = response.name;
                }).catch(error =>{
                    this.cohortTitle = 'Not found';
                });
            },

            hasPermission(permission){
                return helper.hasPermission(permission);
            },
            getBeneficiaries(page){
                if (typeof page !== 'number') {
                    page = 1;
                }
                let url = helper.getFilterURL(this.filterBeneficiaryForm);
                let cohort_id = this.$route.params.cohortId;
                axios.get('/api/beneficiaries/'+ this.$route.params.cohortId +'/enrollment?page=' + page + url + '&cohort_id=' + cohort_id)
                    .then(response => response.data)
                    .then(response => this.beneficiaries = response)
                    .catch(error => {
                        helper.showDataErrorMsg(error);
                    });
            },
            getStatus(beneficiary){
                return beneficiary.is_enrollment ? ('<span class="badge badge-success">'+i18n.beneficiary.enrolled+'</span>') : ('<span class="badge badge-warning">'+i18n.beneficiary.not_enrolled+'</span>') ;
            },
            getSchools(event){
                axios.get('/api/schools-by-lga-id/' + event)
                    .then(response => response.data)
                    .then(response => {
                        this.schools = response.schools;
                    })
                    .catch(error => {
                        helper.showDataErrorMsg(error)
                    });
            },
            markAsFinish(){
                axios.post('/api/beneficiaries/finish-enrollment/'+ this.$route.params.cohortId)
                    .then(response => response.data)
                    .then(response => {
                        this.getBeneficiaries();
                        toastr.success(response.message);
                    })
                    .catch(error => {
                        helper.showDataErrorMsg();
                    });
            },
            getRole(role){
              return helper.getRole(role);
            }
        },
        watch: {
            filterBeneficiaryForm: {
                handler(val){
                    setTimeout(() => {
                        this.getBeneficiaries();
                    }, 500)
                },
                deep: true
            }
        }
    }
</script>
