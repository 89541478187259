var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "content-header row" }, [
      _c("div", { staticClass: "content-header-left col-md-8 col-12 mb-2" }, [
        _c("div", { staticClass: "row breadcrumbs-top" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("h2", { staticClass: "content-header-title float-left mb-0" }, [
              _vm._v(
                _vm._s(_vm.trans("beneficiary.beneficiary")) +
                  "\n                     "
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "breadcrumb-wrapper col-12" }, [
              _c("ol", { staticClass: "breadcrumb" }, [
                _c(
                  "li",
                  { staticClass: "breadcrumb-item" },
                  [
                    _c("router-link", { attrs: { to: "/home" } }, [
                      _vm._v(
                        "\n                                     " +
                          _vm._s(_vm.trans("general.home")) +
                          "\n                                 "
                      ),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  { staticClass: "breadcrumb-item" },
                  [
                    _c("router-link", { attrs: { to: "/home" } }, [
                      _vm._v(
                        "\n                                     " +
                          _vm._s(_vm.trans("beneficiary.beneficiary")) +
                          "\n                                 "
                      ),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("li", { staticClass: "breadcrumb-item active" }, [
                  _vm._v(
                    "\n                                 " +
                      _vm._s(_vm.cohortTitle) +
                      "\n                             "
                  ),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "content-header-right text-md-right col-md-4 col-12 d-md-block d-none",
        },
        [
          _c("div", { staticClass: "form-group breadcrum-right" }, [
            _vm.hasPermission("socu-approval")
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-success btn-sm pull-right ml-1",
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.approveAll()
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "feather icon-send" }),
                    _vm._v(
                      " " + _vm._s(_vm.trans("beneficiary.send_approved"))
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            !_vm.showFilterPanel
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-primary btn-sm pull-right",
                    on: {
                      click: function ($event) {
                        _vm.showFilterPanel = !_vm.showFilterPanel
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "feather icon-filter" }),
                    _vm._v(" " + _vm._s(_vm.trans("general.filter"))),
                  ]
                )
              : _vm._e(),
          ]),
        ]
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "content-body" }, [
      _c("section", { attrs: { id: "basic-datatable" } }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-12" },
            [
              _c("transition", { attrs: { name: "fade" } }, [
                _vm.showFilterPanel
                  ? _c("div", { staticClass: "card border-bottom" }, [
                      _c("div", { staticClass: "card-header" }, [
                        _c("h4", { staticClass: "card-title" }, [
                          _vm._v(
                            "\n                                 " +
                              _vm._s(_vm.trans("general.filter")) +
                              "\n                             "
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "card-content" }, [
                        _c("div", { staticClass: "card-body" }, [
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-6" }, [
                              _c("div", { staticClass: "form-group" }, [
                                _c("label", { attrs: { for: "" } }, [
                                  _vm._v(_vm._s(_vm.trans("general.keyword"))),
                                ]),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.filterBeneficiaryForm.keyword,
                                      expression:
                                        "filterBeneficiaryForm.keyword",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  attrs: { name: "keyword" },
                                  domProps: {
                                    value: _vm.filterBeneficiaryForm.keyword,
                                  },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.filterBeneficiaryForm,
                                        "keyword",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-12" }, [
                              _c(
                                "div",
                                { staticClass: "form-group" },
                                [
                                  _c("date-range-picker", {
                                    attrs: {
                                      "start-date":
                                        _vm.filterBeneficiaryForm.start_date,
                                      "end-date":
                                        _vm.filterBeneficiaryForm.end_date,
                                      label: _vm.trans("general.date_between"),
                                    },
                                    on: {
                                      "update:startDate": function ($event) {
                                        return _vm.$set(
                                          _vm.filterBeneficiaryForm,
                                          "start_date",
                                          $event
                                        )
                                      },
                                      "update:start-date": function ($event) {
                                        return _vm.$set(
                                          _vm.filterBeneficiaryForm,
                                          "start_date",
                                          $event
                                        )
                                      },
                                      "update:endDate": function ($event) {
                                        return _vm.$set(
                                          _vm.filterBeneficiaryForm,
                                          "end_date",
                                          $event
                                        )
                                      },
                                      "update:end-date": function ($event) {
                                        return _vm.$set(
                                          _vm.filterBeneficiaryForm,
                                          "end_date",
                                          $event
                                        )
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _vm.showFilterPanel
                            ? _c(
                                "button",
                                {
                                  staticClass:
                                    "btn btn-danger waves-effect waves-light pull-right mr-1 mb-1",
                                  on: {
                                    click: function ($event) {
                                      _vm.showFilterPanel = !_vm.showFilterPanel
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.trans("general.cancel")))]
                              )
                            : _vm._e(),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card-header" }, [
                  _vm.beneficiaries.total
                    ? _c(
                        "h4",
                        { staticClass: "card-title" },
                        [
                          _c("sort-by", {
                            attrs: {
                              "order-by-options": _vm.orderByOptions,
                              "sort-by": _vm.filterBeneficiaryForm.sort_by,
                              order: _vm.filterBeneficiaryForm.order,
                            },
                            on: {
                              updateSortBy: (value) => {
                                _vm.filterBeneficiaryForm.sort_by = value
                              },
                              updateOrder: (value) => {
                                _vm.filterBeneficiaryForm.order = value
                              },
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("h4", { staticClass: "card-title" }, [
                    _vm.hasPermission("socu-approval")
                      ? _c(
                          "button",
                          {
                            staticClass: "btn btn-success btn-sm pull-right",
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.approveSelected()
                              },
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "feather icon-check-circle",
                            }),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.trans("beneficiary.approve_selected")
                                )
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "card-content" }, [
                  _c(
                    "div",
                    { staticClass: "card-body" },
                    [
                      _vm.beneficiaries.total
                        ? _c("div", { staticClass: "table-responsive" }, [
                            _c("table", { staticClass: "table" }, [
                              _c("thead", [
                                _c("tr", [
                                  _c("th", [
                                    _vm._v(
                                      _vm._s(_vm.trans("general.serial_number"))
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("th", [
                                    _vm._v("Select "),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.allSelected,
                                          expression: "allSelected",
                                        },
                                      ],
                                      attrs: { type: "checkbox" },
                                      domProps: {
                                        checked: Array.isArray(_vm.allSelected)
                                          ? _vm._i(_vm.allSelected, null) > -1
                                          : _vm.allSelected,
                                      },
                                      on: {
                                        click: _vm.selectAll,
                                        change: function ($event) {
                                          var $$a = _vm.allSelected,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = null,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                (_vm.allSelected = $$a.concat([
                                                  $$v,
                                                ]))
                                            } else {
                                              $$i > -1 &&
                                                (_vm.allSelected = $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1)))
                                            }
                                          } else {
                                            _vm.allSelected = $$c
                                          }
                                        },
                                      },
                                    }),
                                  ]),
                                  _vm._v(" "),
                                  _c("th", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.trans(
                                          "beneficiary.admission_number"
                                        )
                                      )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("th", [
                                    _vm._v(
                                      _vm._s(_vm.trans("beneficiary.name"))
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("th", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.trans("beneficiary.socu_status")
                                      )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("th", { staticClass: "table-option" }, [
                                    _vm._v(_vm._s(_vm.trans("general.action"))),
                                  ]),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c(
                                "tbody",
                                _vm._l(
                                  _vm.beneficiaries.data,
                                  function (beneficiary, index) {
                                    return _c("tr", [
                                      _c("td", {
                                        domProps: {
                                          textContent: _vm._s(index + 1),
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("td", [
                                        beneficiary.approved_by_socu != 1
                                          ? _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.beneficiaryIds,
                                                  expression: "beneficiaryIds",
                                                },
                                              ],
                                              attrs: { type: "checkbox" },
                                              domProps: {
                                                value: beneficiary.id,
                                                checked: Array.isArray(
                                                  _vm.beneficiaryIds
                                                )
                                                  ? _vm._i(
                                                      _vm.beneficiaryIds,
                                                      beneficiary.id
                                                    ) > -1
                                                  : _vm.beneficiaryIds,
                                              },
                                              on: {
                                                change: function ($event) {
                                                  var $$a = _vm.beneficiaryIds,
                                                    $$el = $event.target,
                                                    $$c = $$el.checked
                                                      ? true
                                                      : false
                                                  if (Array.isArray($$a)) {
                                                    var $$v = beneficiary.id,
                                                      $$i = _vm._i($$a, $$v)
                                                    if ($$el.checked) {
                                                      $$i < 0 &&
                                                        (_vm.beneficiaryIds =
                                                          $$a.concat([$$v]))
                                                    } else {
                                                      $$i > -1 &&
                                                        (_vm.beneficiaryIds =
                                                          $$a
                                                            .slice(0, $$i)
                                                            .concat(
                                                              $$a.slice($$i + 1)
                                                            ))
                                                    }
                                                  } else {
                                                    _vm.beneficiaryIds = $$c
                                                  }
                                                },
                                              },
                                            })
                                          : _vm._e(),
                                      ]),
                                      _vm._v(" "),
                                      _c("td", {
                                        domProps: {
                                          textContent: _vm._s(
                                            beneficiary.admission_number
                                          ),
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("td", {
                                        domProps: {
                                          textContent: _vm._s(beneficiary.name),
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("td", {
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.getStatus(beneficiary)
                                          ),
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        { staticClass: "table-option" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "btn-group" },
                                            [
                                              _c(
                                                "button",
                                                {
                                                  directives: [
                                                    {
                                                      name: "tooltip",
                                                      rawName: "v-tooltip",
                                                      value:
                                                        beneficiary.approved_by_socu
                                                          ? _vm.trans(
                                                              "beneficiary.reject"
                                                            )
                                                          : _vm.trans(
                                                              "beneficiary.approve"
                                                            ),
                                                      expression:
                                                        "beneficiary.approved_by_socu ? trans('beneficiary.reject') : trans('beneficiary.approve')",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "btn btn-primary btn-sm",
                                                  on: {
                                                    click: function ($event) {
                                                      $event.preventDefault()
                                                      return _vm.toggleStatus(
                                                        beneficiary
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    class: [
                                                      "feather",
                                                      beneficiary.approved_by_socu
                                                        ? "icon-x"
                                                        : "icon-check",
                                                    ],
                                                  }),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ])
                                  }
                                ),
                                0
                              ),
                            ]),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.beneficiaries.total
                        ? _c(
                            "module-info",
                            {
                              attrs: {
                                module: "beneficiary",
                                title: "module_info_title",
                                description: "module_info_description",
                                icon: "check-circle",
                              },
                            },
                            [
                              _c(
                                "div",
                                { attrs: { slot: "btn" }, slot: "btn" },
                                [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-primary btn-md",
                                      on: {
                                        click: function ($event) {
                                          return _vm.$router.push(
                                            "beneficiaries/create"
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "feather icon-plus",
                                      }),
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.trans("general.add_new"))
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("pagination-record", {
                        attrs: {
                          "page-length": _vm.filterBeneficiaryForm.page_length,
                          records: _vm.beneficiaries,
                        },
                        on: {
                          "update:pageLength": function ($event) {
                            return _vm.$set(
                              _vm.filterBeneficiaryForm,
                              "page_length",
                              $event
                            )
                          },
                          "update:page-length": function ($event) {
                            return _vm.$set(
                              _vm.filterBeneficiaryForm,
                              "page_length",
                              $event
                            )
                          },
                          updateRecords: _vm.getBeneficiaries,
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }