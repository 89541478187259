var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    {
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.proceed.apply(null, arguments)
        },
        keydown: function ($event) {
          return _vm.beneficiariesForm.errors.clear($event.target.name)
        },
      },
    },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-xl-12 col-md-6 col-12" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { attrs: { for: "" } }, [
                _vm._v(_vm._s(_vm.trans("beneficiary.cohort"))),
              ]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.beneficiariesForm.cohort_id,
                      expression: "beneficiariesForm.cohort_id",
                    },
                  ],
                  staticClass: "form-control",
                  class: {
                    "is-invalid": _vm.beneficiariesForm.errors.has("cohort_id"),
                  },
                  attrs: { name: "cohort_id", disabled: _vm.id },
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.beneficiariesForm,
                        "cohort_id",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                  },
                },
                [
                  _c("option", { attrs: { value: "" } }, [
                    _vm._v(_vm._s(_vm.trans("beneficiary.cohort"))),
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.cohorts, function (cohort) {
                    return _c("option", { domProps: { value: cohort.id } }, [
                      _vm._v(_vm._s(cohort.name)),
                    ])
                  }),
                ],
                2
              ),
              _vm._v(" "),
              _c("show-error", {
                attrs: {
                  "form-name": _vm.beneficiariesForm,
                  "prop-name": "cohort_id",
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-xl-4 col-md-6 col-12" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { attrs: { for: "" } }, [
                _vm._v(_vm._s(_vm.trans("beneficiary.state"))),
              ]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.beneficiariesForm.state_id,
                      expression: "beneficiariesForm.state_id",
                    },
                  ],
                  staticClass: "form-control",
                  class: {
                    "is-invalid": _vm.beneficiariesForm.errors.has("state_id"),
                  },
                  attrs: { name: "state_id", disabled: _vm.id },
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.beneficiariesForm,
                        "state_id",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                  },
                },
                [
                  _c("option", { attrs: { value: "" } }, [
                    _vm._v(_vm._s(_vm.trans("beneficiary.state"))),
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.states, function (state) {
                    return _c("option", { domProps: { value: state.id } }, [
                      _vm._v(_vm._s(state.name)),
                    ])
                  }),
                ],
                2
              ),
              _vm._v(" "),
              _c("show-error", {
                attrs: {
                  "form-name": _vm.beneficiariesForm,
                  "prop-name": "state_id",
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-xl-4 col-md-6 col-12" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { attrs: { for: "" } }, [
                _vm._v(_vm._s(_vm.trans("beneficiary.lga"))),
              ]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.beneficiariesForm.lga_id,
                      expression: "beneficiariesForm.lga_id",
                    },
                  ],
                  staticClass: "form-control",
                  class: {
                    "is-invalid": _vm.beneficiariesForm.errors.has("lga_id"),
                  },
                  attrs: { name: "lga_id", disabled: _vm.id },
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.beneficiariesForm,
                        "lga_id",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                  },
                },
                [
                  _c("option", { attrs: { value: "" } }, [
                    _vm._v(_vm._s(_vm.trans("beneficiary.lga"))),
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.lgas, function (lga) {
                    return _c("option", { domProps: { value: lga.id } }, [
                      _vm._v(_vm._s(lga.name)),
                    ])
                  }),
                ],
                2
              ),
              _vm._v(" "),
              _c("show-error", {
                attrs: {
                  "form-name": _vm.beneficiariesForm,
                  "prop-name": "lga_id",
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-xl-4 col-md-6 col-12" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { attrs: { for: "" } }, [
                _vm._v(_vm._s(_vm.trans("beneficiary.community"))),
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.beneficiariesForm.community,
                    expression: "beneficiariesForm.community",
                  },
                ],
                staticClass: "form-control",
                class: {
                  "is-invalid": _vm.beneficiariesForm.errors.has("community"),
                },
                attrs: {
                  type: "text",
                  value: "",
                  name: "community",
                  placeholder: _vm.trans("beneficiary.community"),
                  disabled: _vm.id,
                },
                domProps: { value: _vm.beneficiariesForm.community },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.beneficiariesForm,
                      "community",
                      $event.target.value
                    )
                  },
                },
              }),
              _vm._v(" "),
              _c("show-error", {
                attrs: {
                  "form-name": _vm.beneficiariesForm,
                  "prop-name": "community",
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-xl-4 col-md-6 col-12" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { attrs: { for: "" } }, [
                _vm._v(_vm._s(_vm.trans("beneficiary.admission_number"))),
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.beneficiariesForm.admission_number,
                    expression: "beneficiariesForm.admission_number",
                  },
                ],
                staticClass: "form-control",
                class: {
                  "is-invalid":
                    _vm.beneficiariesForm.errors.has("admission_number"),
                },
                attrs: {
                  type: "text",
                  value: "",
                  name: "admission_number",
                  placeholder: _vm.trans("beneficiary.admission_number"),
                },
                domProps: { value: _vm.beneficiariesForm.admission_number },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.beneficiariesForm,
                      "admission_number",
                      $event.target.value
                    )
                  },
                },
              }),
              _vm._v(" "),
              _c("show-error", {
                attrs: {
                  "form-name": _vm.beneficiariesForm,
                  "prop-name": "admission_number",
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-xl-4 col-md-6 col-12" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { attrs: { for: "" } }, [
                _vm._v(_vm._s(_vm.trans("beneficiary.name"))),
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.beneficiariesForm.name,
                    expression: "beneficiariesForm.name",
                  },
                ],
                staticClass: "form-control",
                class: {
                  "is-invalid": _vm.beneficiariesForm.errors.has("name"),
                },
                attrs: {
                  type: "text",
                  value: "",
                  name: "name",
                  placeholder: _vm.trans("beneficiary.name"),
                  disabled: _vm.id,
                },
                domProps: { value: _vm.beneficiariesForm.name },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.beneficiariesForm, "name", $event.target.value)
                  },
                },
              }),
              _vm._v(" "),
              _c("show-error", {
                attrs: {
                  "form-name": _vm.beneficiariesForm,
                  "prop-name": "name",
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-xl-4 col-md-6 col-12" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { attrs: { for: "" } }, [
                _vm._v(_vm._s(_vm.trans("beneficiary.gender"))),
              ]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.beneficiariesForm.gender,
                      expression: "beneficiariesForm.gender",
                    },
                  ],
                  staticClass: "form-control",
                  class: {
                    "is-invalid": _vm.beneficiariesForm.errors.has("gender"),
                  },
                  attrs: { name: "gender" },
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.beneficiariesForm,
                        "gender",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                  },
                },
                [
                  _c("option", { attrs: { value: "" } }, [
                    _vm._v(_vm._s(_vm.trans("beneficiary.gender"))),
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "Male" } }, [_vm._v("Male")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "Female", selected: "" } }, [
                    _vm._v("Female"),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c("show-error", {
                attrs: {
                  "form-name": _vm.beneficiariesForm,
                  "prop-name": "gender",
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-xl-6 col-md-6 col-12" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { attrs: { for: "" } }, [
                _vm._v(_vm._s(_vm.trans("beneficiary.class"))),
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.beneficiariesForm.class,
                    expression: "beneficiariesForm.class",
                  },
                ],
                staticClass: "form-control",
                class: {
                  "is-invalid": _vm.beneficiariesForm.errors.has("class"),
                },
                attrs: {
                  type: "text",
                  value: "",
                  name: "class",
                  placeholder: _vm.trans("beneficiary.class"),
                },
                domProps: { value: _vm.beneficiariesForm.class },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.beneficiariesForm,
                      "class",
                      $event.target.value
                    )
                  },
                },
              }),
              _vm._v(" "),
              _c("show-error", {
                attrs: {
                  "form-name": _vm.beneficiariesForm,
                  "prop-name": "class",
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-xl-6 col-md-6 col-12" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { attrs: { for: "" } }, [
                _vm._v(_vm._s(_vm.trans("beneficiary.distance_from_school"))),
              ]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.beneficiariesForm.distance_from_school,
                      expression: "beneficiariesForm.distance_from_school",
                    },
                  ],
                  staticClass: "form-control",
                  class: {
                    "is-invalid": _vm.beneficiariesForm.errors.has(
                      "distance_from_school"
                    ),
                  },
                  attrs: { name: "distance_from_school" },
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.beneficiariesForm,
                        "distance_from_school",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                  },
                },
                [
                  _c("option", { attrs: { value: "" } }, [
                    _vm._v(
                      _vm._s(_vm.trans("beneficiary.distance_from_school"))
                    ),
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "Within (less than 6km)" } }, [
                    _vm._v("Within (less than 6km)"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "option",
                    { attrs: { value: "Within (greater than 6km)" } },
                    [_vm._v("Within (greater than 6km)")]
                  ),
                ]
              ),
              _vm._v(" "),
              _c("show-error", {
                attrs: {
                  "form-name": _vm.beneficiariesForm,
                  "prop-name": "distance_from_school",
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-xl-4 col-md-6 col-12" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { attrs: { for: "" } }, [
                _vm._v(_vm._s(_vm.trans("beneficiary.date_of_birth"))),
              ]),
              _vm._v(" "),
              _c("datepicker", {
                class: {
                  "form-control is-invalid":
                    _vm.beneficiariesForm.errors.has("date_of_birth"),
                },
                attrs: {
                  bootstrapStyling: true,
                  placeholder: _vm.trans("beneficiary.date_of_birth"),
                },
                on: {
                  selected: function ($event) {
                    return _vm.beneficiariesForm.errors.clear("date_of_birth")
                  },
                },
                model: {
                  value: _vm.beneficiariesForm.date_of_birth,
                  callback: function ($$v) {
                    _vm.$set(_vm.beneficiariesForm, "date_of_birth", $$v)
                  },
                  expression: "beneficiariesForm.date_of_birth",
                },
              }),
              _vm._v(" "),
              _c("show-error", {
                attrs: {
                  "form-name": _vm.beneficiariesForm,
                  "prop-name": "date_of_birth",
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-xl-4 col-md-6 col-12" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { attrs: { for: "" } }, [
                _vm._v(_vm._s(_vm.trans("beneficiary.age"))),
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.beneficiariesForm.age,
                    expression: "beneficiariesForm.age",
                  },
                ],
                staticClass: "form-control",
                class: {
                  "is-invalid": _vm.beneficiariesForm.errors.has("age"),
                },
                attrs: {
                  type: "text",
                  value: "",
                  name: "age",
                  placeholder: _vm.trans("beneficiary.age"),
                },
                domProps: { value: _vm.beneficiariesForm.age },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.beneficiariesForm, "age", $event.target.value)
                  },
                },
              }),
              _vm._v(" "),
              _c("show-error", {
                attrs: {
                  "form-name": _vm.beneficiariesForm,
                  "prop-name": "age",
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-xl-4 col-md-6 col-12" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { attrs: { for: "" } }, [
                _vm._v(_vm._s(_vm.trans("beneficiary.disability"))),
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.beneficiariesForm.disability,
                    expression: "beneficiariesForm.disability",
                  },
                ],
                staticClass: "form-control",
                class: {
                  "is-invalid": _vm.beneficiariesForm.errors.has("disability"),
                },
                attrs: {
                  type: "text",
                  value: "",
                  name: "disability",
                  placeholder: _vm.trans("beneficiary.disability"),
                },
                domProps: { value: _vm.beneficiariesForm.disability },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.beneficiariesForm,
                      "disability",
                      $event.target.value
                    )
                  },
                },
              }),
              _vm._v(" "),
              _c("show-error", {
                attrs: {
                  "form-name": _vm.beneficiariesForm,
                  "prop-name": "disability",
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-xl-3 col-md-6 col-12" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { attrs: { for: "" } }, [
                _vm._v(_vm._s(_vm.trans("beneficiary.have_text_book"))),
              ]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.beneficiariesForm.have_text_book,
                      expression: "beneficiariesForm.have_text_book",
                    },
                  ],
                  staticClass: "form-control",
                  class: {
                    "is-invalid":
                      _vm.beneficiariesForm.errors.has("have_text_book"),
                  },
                  attrs: { name: "have_text_book" },
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.beneficiariesForm,
                        "have_text_book",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                  },
                },
                [
                  _c("option", { attrs: { value: "" } }, [
                    _vm._v(_vm._s(_vm.trans("beneficiary.have_text_book"))),
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "1" } }, [_vm._v("Yes")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "0" } }, [_vm._v("No")]),
                ]
              ),
              _vm._v(" "),
              _c("show-error", {
                attrs: {
                  "form-name": _vm.beneficiariesForm,
                  "prop-name": "have_text_book",
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-xl-3 col-md-6 col-12" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { attrs: { for: "" } }, [
                _vm._v(_vm._s(_vm.trans("beneficiary.have_uniform"))),
              ]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.beneficiariesForm.have_uniform,
                      expression: "beneficiariesForm.have_uniform",
                    },
                  ],
                  staticClass: "form-control",
                  class: {
                    "is-invalid":
                      _vm.beneficiariesForm.errors.has("have_uniform"),
                  },
                  attrs: { name: "have_uniform" },
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.beneficiariesForm,
                        "have_uniform",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                  },
                },
                [
                  _c("option", { attrs: { value: "" } }, [
                    _vm._v(_vm._s(_vm.trans("beneficiary.have_uniform"))),
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "1" } }, [_vm._v("Yes")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "0" } }, [_vm._v("No")]),
                ]
              ),
              _vm._v(" "),
              _c("show-error", {
                attrs: {
                  "form-name": _vm.beneficiariesForm,
                  "prop-name": "have_uniform",
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-xl-3 col-md-6 col-12" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { attrs: { for: "" } }, [
                _vm._v(_vm._s(_vm.trans("beneficiary.have_writing_material"))),
              ]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.beneficiariesForm.have_writing_material,
                      expression: "beneficiariesForm.have_writing_material",
                    },
                  ],
                  staticClass: "form-control",
                  class: {
                    "is-invalid": _vm.beneficiariesForm.errors.has(
                      "have_writing_material"
                    ),
                  },
                  attrs: { name: "have_writing_material" },
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.beneficiariesForm,
                        "have_writing_material",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                  },
                },
                [
                  _c("option", { attrs: { value: "" } }, [
                    _vm._v(
                      _vm._s(_vm.trans("beneficiary.have_writing_material"))
                    ),
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "1" } }, [_vm._v("Yes")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "0" } }, [_vm._v("No")]),
                ]
              ),
              _vm._v(" "),
              _c("show-error", {
                attrs: {
                  "form-name": _vm.beneficiariesForm,
                  "prop-name": "have_writing_material",
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-xl-3 col-md-6 col-12" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { attrs: { for: "" } }, [
                _vm._v(_vm._s(_vm.trans("beneficiary.have_school_bag"))),
              ]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.beneficiariesForm.have_school_bag,
                      expression: "beneficiariesForm.have_school_bag",
                    },
                  ],
                  staticClass: "form-control",
                  class: {
                    "is-invalid":
                      _vm.beneficiariesForm.errors.has("have_school_bag"),
                  },
                  attrs: { name: "have_school_bag" },
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.beneficiariesForm,
                        "have_school_bag",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                  },
                },
                [
                  _c("option", { attrs: { value: "" } }, [
                    _vm._v(_vm._s(_vm.trans("beneficiary.have_school_bag"))),
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "1" } }, [_vm._v("Yes")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "0" } }, [_vm._v("No")]),
                ]
              ),
              _vm._v(" "),
              _c("show-error", {
                attrs: {
                  "form-name": _vm.beneficiariesForm,
                  "prop-name": "have_school_bag",
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-xl-4 col-md-6 col-12" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { attrs: { for: "" } }, [
                _vm._v(_vm._s(_vm.trans("beneficiary.caregiver_name"))),
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.beneficiariesForm.caregiver_name,
                    expression: "beneficiariesForm.caregiver_name",
                  },
                ],
                staticClass: "form-control",
                class: {
                  "is-invalid":
                    _vm.beneficiariesForm.errors.has("caregiver_name"),
                },
                attrs: {
                  type: "text",
                  value: "",
                  name: "caregiver_name",
                  placeholder: _vm.trans("beneficiary.caregiver_name"),
                },
                domProps: { value: _vm.beneficiariesForm.caregiver_name },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.beneficiariesForm,
                      "caregiver_name",
                      $event.target.value
                    )
                  },
                },
              }),
              _vm._v(" "),
              _c("show-error", {
                attrs: {
                  "form-name": _vm.beneficiariesForm,
                  "prop-name": "caregiver_name",
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-xl-4 col-md-6 col-12" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { attrs: { for: "" } }, [
                _vm._v(_vm._s(_vm.trans("beneficiary.caregiver_phone"))),
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.beneficiariesForm.caregiver_phone,
                    expression: "beneficiariesForm.caregiver_phone",
                  },
                ],
                staticClass: "form-control",
                class: {
                  "is-invalid":
                    _vm.beneficiariesForm.errors.has("caregiver_phone"),
                },
                attrs: {
                  type: "text",
                  value: "",
                  name: "caregiver_phone",
                  placeholder: _vm.trans("beneficiary.caregiver_phone"),
                },
                domProps: { value: _vm.beneficiariesForm.caregiver_phone },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.beneficiariesForm,
                      "caregiver_phone",
                      $event.target.value
                    )
                  },
                },
              }),
              _vm._v(" "),
              _c("show-error", {
                attrs: {
                  "form-name": _vm.beneficiariesForm,
                  "prop-name": "caregiver_phone",
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-xl-4 col-md-6 col-12" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { attrs: { for: "" } }, [
                _vm._v(_vm._s(_vm.trans("beneficiary.caregiver_gender"))),
              ]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.beneficiariesForm.caregiver_gender,
                      expression: "beneficiariesForm.caregiver_gender",
                    },
                  ],
                  staticClass: "form-control",
                  class: {
                    "is-invalid":
                      _vm.beneficiariesForm.errors.has("caregiver_gender"),
                  },
                  attrs: { name: "caregiver_gender" },
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.beneficiariesForm,
                        "caregiver_gender",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                  },
                },
                [
                  _c("option", { attrs: { value: "" } }, [
                    _vm._v(_vm._s(_vm.trans("beneficiary.caregiver_gender"))),
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "Male" } }, [_vm._v("Male")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "Female", selected: "" } }, [
                    _vm._v("Female"),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c("show-error", {
                attrs: {
                  "form-name": _vm.beneficiariesForm,
                  "prop-name": "caregiver_gender",
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-xl-6 col-md-6 col-12" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { attrs: { for: "" } }, [
                _vm._v(
                  _vm._s(_vm.trans("beneficiary.caregiver_date_of_birth"))
                ),
              ]),
              _vm._v(" "),
              _c("datepicker", {
                class: {
                  "form-control is-invalid": _vm.beneficiariesForm.errors.has(
                    "caregiver_date_of_birth"
                  ),
                },
                attrs: {
                  bootstrapStyling: true,
                  placeholder: _vm.trans("beneficiary.caregiver_date_of_birth"),
                },
                on: {
                  selected: function ($event) {
                    return _vm.beneficiariesForm.errors.clear(
                      "caregiver_date_of_birth"
                    )
                  },
                },
                model: {
                  value: _vm.beneficiariesForm.caregiver_date_of_birth,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.beneficiariesForm,
                      "caregiver_date_of_birth",
                      $$v
                    )
                  },
                  expression: "beneficiariesForm.caregiver_date_of_birth",
                },
              }),
              _vm._v(" "),
              _c("show-error", {
                attrs: {
                  "form-name": _vm.beneficiariesForm,
                  "prop-name": "caregiver_date_of_birth",
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-xl-6 col-md-6 col-12" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { attrs: { for: "" } }, [
                _vm._v(_vm._s(_vm.trans("beneficiary.caregiver_age"))),
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.beneficiariesForm.caregiver_age,
                    expression: "beneficiariesForm.caregiver_age",
                  },
                ],
                staticClass: "form-control",
                class: {
                  "is-invalid":
                    _vm.beneficiariesForm.errors.has("caregiver_age"),
                },
                attrs: {
                  type: "text",
                  value: "",
                  name: "caregiver_age",
                  placeholder: _vm.trans("beneficiary.caregiver_age"),
                },
                domProps: { value: _vm.beneficiariesForm.caregiver_age },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.beneficiariesForm,
                      "caregiver_age",
                      $event.target.value
                    )
                  },
                },
              }),
              _vm._v(" "),
              _c("show-error", {
                attrs: {
                  "form-name": _vm.beneficiariesForm,
                  "prop-name": "caregiver_age",
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-xl-4 col-md-6 col-12" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { attrs: { for: "" } }, [
                _vm._v(
                  _vm._s(_vm.trans("beneficiary.caregiver_education_status"))
                ),
              ]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.beneficiariesForm.caregiver_education_status,
                      expression:
                        "beneficiariesForm.caregiver_education_status",
                    },
                  ],
                  staticClass: "form-control",
                  class: {
                    "is-invalid": _vm.beneficiariesForm.errors.has(
                      "caregiver_education_status"
                    ),
                  },
                  attrs: { name: "caregiver_education_status" },
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.beneficiariesForm,
                        "caregiver_education_status",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                  },
                },
                [
                  _c("option", { attrs: { value: "" } }, [
                    _vm._v(
                      _vm._s(
                        _vm.trans("beneficiary.caregiver_education_status")
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "NONE", selected: "" } }, [
                    _vm._v("NONE"),
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "PRIMARY" } }, [
                    _vm._v("PRIMARY"),
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "JSS" } }, [_vm._v("JSS")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "SSCE" } }, [_vm._v("SSCE")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "NCE" } }, [_vm._v("NCE")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "OND" } }, [_vm._v("OND")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "HND" } }, [_vm._v("HND")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "BACHELOR" } }, [
                    _vm._v("BACHELOR"),
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "OTHER" } }, [
                    _vm._v("OTHER"),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c("show-error", {
                attrs: {
                  "form-name": _vm.beneficiariesForm,
                  "prop-name": "caregiver_education_status",
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-xl-4 col-md-6 col-12" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { attrs: { for: "" } }, [
                _vm._v(
                  _vm._s(_vm.trans("beneficiary.caregiver_employment_status"))
                ),
              ]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.beneficiariesForm.caregiver_employment_status,
                      expression:
                        "beneficiariesForm.caregiver_employment_status",
                    },
                  ],
                  staticClass: "form-control",
                  class: {
                    "is-invalid": _vm.beneficiariesForm.errors.has(
                      "caregiver_employment_status"
                    ),
                  },
                  attrs: { name: "caregiver_employment_status" },
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.beneficiariesForm,
                        "caregiver_employment_status",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                  },
                },
                [
                  _c("option", { attrs: { value: "" } }, [
                    _vm._v(
                      _vm._s(
                        _vm.trans("beneficiary.caregiver_employment_status")
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "NONE", selected: "" } }, [
                    _vm._v("NONE"),
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "Employee (Government)" } }, [
                    _vm._v("Employee (Government)"),
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "Housewife" } }, [
                    _vm._v("Housewife"),
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "Self-Employed" } }, [
                    _vm._v("Self-Employed"),
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "Dependant" } }, [
                    _vm._v("Dependant"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "option",
                    { attrs: { value: "Employee (Private Sector Ngo)" } },
                    [_vm._v("Employee (Private Sector Ngo)")]
                  ),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "Pensioner" } }, [
                    _vm._v("Pensioner"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "option",
                    { attrs: { value: "Self-Employed (Nonagric)" } },
                    [_vm._v("Self-Employed (Nonagric)")]
                  ),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "Employer" } }, [
                    _vm._v("Employer"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "option",
                    { attrs: { value: "Domestic Employee (House Help)" } },
                    [_vm._v("Domestic Employee (House Help)")]
                  ),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "Pupil Student" } }, [
                    _vm._v("Pupil Student"),
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "Apprentice Intern" } }, [
                    _vm._v("Apprentice Intern"),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c("show-error", {
                attrs: {
                  "form-name": _vm.beneficiariesForm,
                  "prop-name": "caregiver_employment_status",
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-xl-4 col-md-6 col-12" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { attrs: { for: "" } }, [
                _vm._v(
                  _vm._s(_vm.trans("beneficiary.caregiver_relationship_status"))
                ),
              ]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value:
                        _vm.beneficiariesForm.caregiver_relationship_status,
                      expression:
                        "beneficiariesForm.caregiver_relationship_status",
                    },
                  ],
                  staticClass: "form-control",
                  class: {
                    "is-invalid": _vm.beneficiariesForm.errors.has(
                      "caregiver_relationship_status"
                    ),
                  },
                  attrs: { name: "caregiver_relationship_status" },
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.beneficiariesForm,
                        "caregiver_relationship_status",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                  },
                },
                [
                  _c("option", { attrs: { value: "" } }, [
                    _vm._v(
                      _vm._s(
                        _vm.trans("beneficiary.caregiver_relationship_status")
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "option",
                    { attrs: { value: "Child (Don Daughter)", selected: "" } },
                    [_vm._v("Child (Don Daughter)")]
                  ),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "Niece Nephew" } }, [
                    _vm._v("Niece Nephew"),
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "Grandchild" } }, [
                    _vm._v("Grandchild"),
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "Other Relationship" } }, [
                    _vm._v("Other Relationship"),
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "Non Relative" } }, [
                    _vm._v("Non Relative"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "option",
                    { attrs: { value: "Adopted Foster Step Child" } },
                    [_vm._v("Adopted Foster Step Child")]
                  ),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "Parent Parent In Law" } }, [
                    _vm._v("Parent Parent In Law"),
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "House Help" } }, [
                    _vm._v("House Help"),
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "Don Daughter In Law" } }, [
                    _vm._v("Don Daughter In Law"),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c("show-error", {
                attrs: {
                  "form-name": _vm.beneficiariesForm,
                  "prop-name": "caregiver_relationship_status",
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-xl-12 col-md-6 col-12" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { attrs: { for: "" } }, [
                _vm._v(_vm._s(_vm.trans("beneficiary.school"))),
              ]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.beneficiariesForm.school_id,
                      expression: "beneficiariesForm.school_id",
                    },
                  ],
                  staticClass: "form-control",
                  class: {
                    "is-invalid": _vm.beneficiariesForm.errors.has("school_id"),
                  },
                  attrs: { name: "school_id" },
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.beneficiariesForm,
                        "school_id",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                  },
                },
                [
                  _c("option", { attrs: { value: "" } }, [
                    _vm._v(_vm._s(_vm.trans("beneficiary.school"))),
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.schools, function (school) {
                    return _c("option", { domProps: { value: school.id } }, [
                      _vm._v(_vm._s(school.name)),
                    ])
                  }),
                ],
                2
              ),
              _vm._v(" "),
              _c("show-error", {
                attrs: {
                  "form-name": _vm.beneficiariesForm,
                  "prop-name": "school_id",
                },
              }),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-8 offset-md-4" }, [
          _c(
            "button",
            {
              staticClass:
                "btn btn-primary mr-1 mb-1 waves-effect waves-light pull-right",
              attrs: { type: "submit" },
            },
            [
              _vm.id && !_vm.enrollment
                ? _c("span", [_vm._v(_vm._s(_vm.trans("general.update")))])
                : _vm.enrollment === "enrollment"
                ? _c("span", [_vm._v(_vm._s(_vm.trans("beneficiary.enroll")))])
                : _c("span", [_vm._v(_vm._s(_vm.trans("general.save")))]),
            ]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass:
                "btn btn-danger waves-effect waves-light pull-right mr-1 mb-1",
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  return _vm.$router.go(-1)
                },
              },
            },
            [_vm._v(_vm._s(_vm.trans("general.cancel")) + "\n            ")]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }