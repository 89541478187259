<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-8 col-12 mb-2">
                <div class="row breadcrumbs-top">
                    <div class="col-12">
                        <h2 class="content-header-title float-left mb-0">{{trans('beneficiary.beneficiary')}}
                        </h2>
                        <div class="breadcrumb-wrapper col-12">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item">
                                    <router-link to="/home">
                                        {{ trans('general.home') }}
                                    </router-link>
                                </li>
                                <li class="breadcrumb-item">
                                    <router-link to="/home">
                                        {{ trans('beneficiary.beneficiary') }}
                                    </router-link>
                                </li>
                                <li class="breadcrumb-item active">
                                    {{ cohortTitle }}
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content-header-right text-md-right col-md-4 col-12 d-md-block d-none">
                <div class="form-group breadcrum-right">
                    <button class="btn btn-success btn-sm pull-right ml-1" @click.prevent="approveAll()" v-if="hasPermission('socu-approval')"><i class="feather icon-send"></i> {{trans('beneficiary.send_approved')}}</button>
                    
                    <button class="btn btn-primary btn-sm pull-right" v-if="!showFilterPanel" @click="showFilterPanel = !showFilterPanel"><i class="feather icon-filter"></i> {{trans('general.filter')}}</button>
                    
                </div>
            </div>

        </div>

    <div class="content-body">
        <section id="basic-datatable">
            <div class="row">
                <div class="col-12">

                    <transition name="fade">
                        <div class="card border-bottom" v-if="showFilterPanel">
                            <div class="card-header">
                                <h4 class="card-title">
                                    {{trans('general.filter')}}
                                </h4>
                            </div>
                            <div class="card-content">
                                <div class="card-body">
                                <div class="row">
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label for="">{{trans('general.keyword')}}</label>
                                            <input class="form-control" name="keyword" v-model="filterBeneficiaryForm.keyword">
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <date-range-picker :start-date.sync="filterBeneficiaryForm.start_date" :end-date.sync="filterBeneficiaryForm.end_date" :label="trans('general.date_between')"></date-range-picker>
                                        </div>
                                    </div>
                                </div>
                                <button class="btn btn-danger waves-effect waves-light pull-right mr-1 mb-1" v-if="showFilterPanel" @click="showFilterPanel = !showFilterPanel">{{trans('general.cancel')}}</button>
                            </div>
                            </div>
                        </div>
                    </transition>
                    
                    <div class="card">
                        <div class="card-header">
                            <h4 v-if="beneficiaries.total" class="card-title">
                                <sort-by :order-by-options="orderByOptions" :sort-by="filterBeneficiaryForm.sort_by" :order="filterBeneficiaryForm.order" @updateSortBy="value => {filterBeneficiaryForm.sort_by = value}"  @updateOrder="value => {filterBeneficiaryForm.order = value}"></sort-by>
                            </h4>

                            <h4 class="card-title">
                                <button class="btn btn-success btn-sm pull-right" @click.prevent="approveSelected()" v-if="hasPermission('socu-approval')"><i class="feather icon-check-circle"></i> {{trans('beneficiary.approve_selected')}}</button>
                            </h4>

                        </div>
                        <div class="card-content">
                            <div class="card-body">
                                <div class="table-responsive" v-if="beneficiaries.total">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th>{{trans('general.serial_number')}}</th>
                                            <th>Select <input type="checkbox" @click="selectAll" v-model="allSelected"></th>
                                            <th>{{trans('beneficiary.admission_number')}}</th>
                                            <th>{{trans('beneficiary.name')}}</th>
                                            <th>{{trans('beneficiary.socu_status')}}</th>
                                            <th class="table-option">{{trans('general.action')}}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(beneficiary, index) in beneficiaries.data">
                                            <td v-text="index+1"></td>
                                            <td><input type="checkbox" v-model="beneficiaryIds" :value=" beneficiary.id" v-if="beneficiary.approved_by_socu != 1"></td>
                                            <td v-text="beneficiary.admission_number"></td>
                                            <td v-text="beneficiary.name"></td>
                                            <td v-html="getStatus(beneficiary)"></td>
                                            <td class="table-option">
                                                <div class="btn-group">
                                                    <button class="btn btn-primary btn-sm" v-tooltip="beneficiary.approved_by_socu ? trans('beneficiary.reject') : trans('beneficiary.approve')" @click.prevent="toggleStatus(beneficiary)">
                                                        <i :class="['feather', (beneficiary.approved_by_socu ?  'icon-x' : 'icon-check')]"></i>
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <module-info v-if="!beneficiaries.total" module="beneficiary" title="module_info_title" description="module_info_description" icon="check-circle">
                                <div slot="btn">
                                    <button class="btn btn-primary btn-md" @click="$router.push('beneficiaries/create')"><i class="feather icon-plus"></i> {{trans('general.add_new')}}</button>
                                </div>
                            </module-info>
                            <pagination-record :page-length.sync="filterBeneficiaryForm.page_length" :records="beneficiaries" @updateRecords="getBeneficiaries"></pagination-record>
                             
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
   
   </div>
    </div>
</template>

<script>
    import sortBy from '../../components/sort-by'
    import dateRangePicker from '../../components/date-range-picker'

    export default {
        components : { sortBy,dateRangePicker },
        data() {
            return {
                userRole: '',
                beneficiaries: {
                    total: 0,
                    data: []
                },
                filterBeneficiaryForm: {
                    keyword: '',
                    start_date: '',
                    end_date: '',
                    sort_by : 'created_at',
                    order: 'desc',
                    page_length: helper.getConfig('page_length')
                },
                orderByOptions: [
                    {
                        value: 'admission_number',
                        translation: i18n.beneficiary.admission_number
                    },
                    {
                        value: 'name',
                        translation: i18n.beneficiary.name
                    },
                    {
                        value: 'email',
                        translation: i18n.beneficiary.email
                    }
                ],
                showFilterPanel: false,
                selected: [],
                allSelected: false,
                beneficiaryIds: [],
                cohortTitle: ''
            };
        },
        mounted(){
            if(!helper.hasPermission('socu-approval')){
                helper.notAccessibleMsg();
                this.$router.push('/home');
            }

            if(!helper.featureAvailable('beneficiary')){
                helper.featureNotAvailableMsg();
                this.$router.push('/home');
            }
            this.getBeneficiaries();
            this.getBatchTitle();
        },
        methods: {
            getBatchTitle(){
                axios.get('/api/setting/cohort/' + this.$route.params.cohortId).then(response => response.data).then(response =>  {
                    this.cohortTitle = response.name;
                }).catch(error =>{
                    return 'Not found';
                });
            },

            hasPermission(permission){
                return helper.hasPermission(permission);
            },
            getBeneficiaries(page){
                if (typeof page !== 'number') {
                    page = 1;
                }
                let url = helper.getFilterURL(this.filterBeneficiaryForm);
                let cohort_id = this.$route.params.cohortId;
                axios.get('/api/beneficiaries/'+ this.$route.params.cohortId +'/socu-approve?page=' + page + url + '&cohort_id=' + cohort_id)
                    .then(response => response.data)
                    .then(response => this.beneficiaries = response)
                    .catch(error => {
                        helper.showDataErrorMsg(error);
                    });
            },
            
            getStatus(beneficiary){
                return beneficiary.approved_by_socu ? ('<span class="badge badge-success">'+i18n.beneficiary.approved+'</span>') : ('<span class="badge badge-warning">'+i18n.beneficiary.pending+'</span>') ;
            },
            toggleStatus(beneficiary){
                axios.post('/api/beneficiaries/'+beneficiary.id+'/socu-status')
                    .then(response => response.data)
                    .then(response => {
                        this.getBeneficiaries();
                        toastr.success(response.message);
                    })
                    .catch(error => {
                        helper.showDataErrorMsg();
                    });
            },
            selectAll: function() {
                this.beneficiaryIds = [];

                if (!this.allSelected) {
                    for (var beneficiary in this.beneficiaries.data) {
                        this.beneficiaryIds.push(this.beneficiaries.data[beneficiary].id);
                    }
                }
            },
            approveAll(){
                axios.post('/api/beneficiaries/'+ this.$route.params.cohortId +'/socu-submit-approve')
                    .then(response => response.data)
                    .then(response => {
                        this.getBeneficiaries();
                        toastr.success(response.message);
                    })
                    .catch(error => {
                        helper.showDataErrorMsg();
                    });
            },

            approveSelected(){
                axios.post('/api/beneficiaries/approve-selected/'+ this.beneficiaryIds)
                    .then(response => response.data)
                    .then(response => {
                        this.getBeneficiaries();
                        toastr.success(response.message);
                    })
                    .catch(error => {
                        helper.showDataErrorMsg();
                    });
            },
        },
        watch: {
            filterBeneficiaryForm: {
                handler(val){
                    setTimeout(() => {
                        this.getBeneficiaries();
                    }, 500)
                },
                deep: true
            }
        }
    }
</script>
