<template>
    <form @submit.prevent="proceed" @keydown="beneficiariesForm.errors.clear($event.target.name)">
        <div class="row">
            <div class="col-xl-12 col-md-6 col-12">
                <div class="form-group">
                    <label for="">{{trans('beneficiary.cohort')}}</label>
                    <select class="form-control" :class="{ 'is-invalid': beneficiariesForm.errors.has('cohort_id') }" name="cohort_id" v-model="beneficiariesForm.cohort_id" :disabled="id">
                        <option value="">{{trans('beneficiary.cohort')}}</option>
                        <option v-for="cohort in cohorts" :value="cohort.id">{{cohort.name}}</option>
                    </select>
                    <show-error :form-name="beneficiariesForm" prop-name="cohort_id"></show-error>
                </div>
            </div>

            <div class="col-xl-4 col-md-6 col-12">
                <div class="form-group">
                    <label for="">{{trans('beneficiary.state')}}</label>
                    <select class="form-control" :class="{ 'is-invalid': beneficiariesForm.errors.has('state_id') }" name="state_id" v-model="beneficiariesForm.state_id" :disabled="id">
                        <option value="">{{trans('beneficiary.state')}}</option>
                        <option v-for="state in states" :value="state.id">{{state.name}}</option>
                    </select>
                    <show-error :form-name="beneficiariesForm" prop-name="state_id"></show-error>
                </div>
            </div>

            <div class="col-xl-4 col-md-6 col-12">
                <div class="form-group">
                    <label for="">{{trans('beneficiary.lga')}}</label>
                    <select class="form-control" :class="{ 'is-invalid': beneficiariesForm.errors.has('lga_id') }" name="lga_id" v-model="beneficiariesForm.lga_id" :disabled="id">
                        <option value="">{{trans('beneficiary.lga')}}</option>
                        <option v-for="lga in lgas" :value="lga.id">{{lga.name}}</option>
                    </select>
                    <show-error :form-name="beneficiariesForm" prop-name="lga_id"></show-error>
                </div>
            </div>

            <div class="col-xl-4 col-md-6 col-12">
                <div class="form-group">
                    <label for="">{{trans('beneficiary.community')}}</label>
                    <input class="form-control" :class="{ 'is-invalid': beneficiariesForm.errors.has('community') }" type="text" value="" v-model="beneficiariesForm.community" name="community" :placeholder="trans('beneficiary.community')" :disabled="id">
                    <show-error :form-name="beneficiariesForm" prop-name="community"></show-error>
                </div>
            </div>

            <div class="col-xl-4 col-md-6 col-12">
                <div class="form-group">
                    <label for="">{{trans('beneficiary.admission_number')}}</label>
                    <input class="form-control" :class="{ 'is-invalid': beneficiariesForm.errors.has('admission_number') }" type="text" value="" v-model="beneficiariesForm.admission_number" name="admission_number" :placeholder="trans('beneficiary.admission_number')">
                    <show-error :form-name="beneficiariesForm" prop-name="admission_number"></show-error>
                </div>
            </div>

            <div class="col-xl-4 col-md-6 col-12">
                <div class="form-group">
                    <label for="">{{trans('beneficiary.name')}}</label>
                    <input class="form-control" :class="{ 'is-invalid': beneficiariesForm.errors.has('name') }" type="text" value="" v-model="beneficiariesForm.name" name="name" :placeholder="trans('beneficiary.name')" :disabled="id">
                    <show-error :form-name="beneficiariesForm" prop-name="name"></show-error>
                </div>
            </div>
            
            <div class="col-xl-4 col-md-6 col-12">
                <div class="form-group">
                    <label for="">{{trans('beneficiary.gender')}}</label>
                    <select class="form-control" :class="{ 'is-invalid': beneficiariesForm.errors.has('gender') }" name="gender" v-model="beneficiariesForm.gender">
                        <option value="">{{trans('beneficiary.gender')}}</option>
                        <option value="Male">Male</option>
                        <option value="Female" selected>Female</option>
                    </select>
                    <show-error :form-name="beneficiariesForm" prop-name="gender"></show-error>
                </div>
            </div>
            
            <div class="col-xl-6 col-md-6 col-12">
                <div class="form-group">
                    <label for="">{{trans('beneficiary.class')}}</label>
                    <input class="form-control" :class="{ 'is-invalid': beneficiariesForm.errors.has('class') }" type="text" value="" v-model="beneficiariesForm.class" name="class" :placeholder="trans('beneficiary.class')">
                    <show-error :form-name="beneficiariesForm" prop-name="class"></show-error>
                </div>
            </div>

            <div class="col-xl-6 col-md-6 col-12">
                <div class="form-group">
                    <label for="">{{trans('beneficiary.distance_from_school')}}</label>
                    <select class="form-control" :class="{ 'is-invalid': beneficiariesForm.errors.has('distance_from_school') }" name="distance_from_school" v-model="beneficiariesForm.distance_from_school">
                        <option value="">{{trans('beneficiary.distance_from_school')}}</option>
                        <option value="Within (less than 6km)">Within (less than 6km)</option>
                        <option value="Within (greater than 6km)">Within (greater than 6km)</option>
                    </select>
                    <show-error :form-name="beneficiariesForm" prop-name="distance_from_school"></show-error>
                </div>
            </div>

            <div class="col-xl-4 col-md-6 col-12">
                <div class="form-group">
                    <label for="">{{trans('beneficiary.date_of_birth')}}</label>
                    <datepicker v-model="beneficiariesForm.date_of_birth" :bootstrapStyling="true" @selected="beneficiariesForm.errors.clear('date_of_birth')"  :class="{ 'form-control is-invalid': beneficiariesForm.errors.has('date_of_birth') }" :placeholder="trans('beneficiary.date_of_birth')"></datepicker>
                    <show-error :form-name="beneficiariesForm" prop-name="date_of_birth"></show-error>
                </div>
            </div>

            <div class="col-xl-4 col-md-6 col-12">
                <div class="form-group">
                    <label for="">{{trans('beneficiary.age')}}</label>
                    <input class="form-control" :class="{ 'is-invalid': beneficiariesForm.errors.has('age') }" type="text" value="" v-model="beneficiariesForm.age" name="age" :placeholder="trans('beneficiary.age')">
                    <show-error :form-name="beneficiariesForm" prop-name="age"></show-error>
                </div>
            </div>

            <div class="col-xl-4 col-md-6 col-12">
                <div class="form-group">
                    <label for="">{{trans('beneficiary.disability')}}</label>
                    <input class="form-control" :class="{ 'is-invalid': beneficiariesForm.errors.has('disability') }" type="text" value="" v-model="beneficiariesForm.disability" name="disability" :placeholder="trans('beneficiary.disability')">
                    <show-error :form-name="beneficiariesForm" prop-name="disability"></show-error>
                </div>
            </div>

            <div class="col-xl-3 col-md-6 col-12">
                <div class="form-group">
                    <label for="">{{trans('beneficiary.have_text_book')}}</label>
                    <select class="form-control" :class="{ 'is-invalid': beneficiariesForm.errors.has('have_text_book') }" name="have_text_book" v-model="beneficiariesForm.have_text_book">
                        <option value="">{{trans('beneficiary.have_text_book')}}</option>
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                    </select>
                    <show-error :form-name="beneficiariesForm" prop-name="have_text_book"></show-error>
                </div>
            </div>

            <div class="col-xl-3 col-md-6 col-12">
                <div class="form-group">
                    <label for="">{{trans('beneficiary.have_uniform')}}</label>
                    <select class="form-control" :class="{ 'is-invalid': beneficiariesForm.errors.has('have_uniform') }" name="have_uniform" v-model="beneficiariesForm.have_uniform">
                        <option value="">{{trans('beneficiary.have_uniform')}}</option>
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                    </select>
                    <show-error :form-name="beneficiariesForm" prop-name="have_uniform"></show-error>
                </div>
            </div>

            <div class="col-xl-3 col-md-6 col-12">
                <div class="form-group">
                    <label for="">{{trans('beneficiary.have_writing_material')}}</label>
                    <select class="form-control" :class="{ 'is-invalid': beneficiariesForm.errors.has('have_writing_material') }" name="have_writing_material" v-model="beneficiariesForm.have_writing_material">
                        <option value="">{{trans('beneficiary.have_writing_material')}}</option>
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                    </select>
                    <show-error :form-name="beneficiariesForm" prop-name="have_writing_material"></show-error>
                </div>
            </div>

            <div class="col-xl-3 col-md-6 col-12">
                <div class="form-group">
                    <label for="">{{trans('beneficiary.have_school_bag')}}</label>
                    <select class="form-control" :class="{ 'is-invalid': beneficiariesForm.errors.has('have_school_bag') }" name="have_school_bag" v-model="beneficiariesForm.have_school_bag">
                        <option value="">{{trans('beneficiary.have_school_bag')}}</option>
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                    </select>
                    <show-error :form-name="beneficiariesForm" prop-name="have_school_bag"></show-error>
                </div>
            </div>

            <div class="col-xl-4 col-md-6 col-12">
                <div class="form-group">
                    <label for="">{{trans('beneficiary.caregiver_name')}}</label>
                    <input class="form-control" :class="{ 'is-invalid': beneficiariesForm.errors.has('caregiver_name') }" type="text" value="" v-model="beneficiariesForm.caregiver_name" name="caregiver_name" :placeholder="trans('beneficiary.caregiver_name')">
                    <show-error :form-name="beneficiariesForm" prop-name="caregiver_name"></show-error>
                </div>
            </div>

            <div class="col-xl-4 col-md-6 col-12">
                <div class="form-group">
                    <label for="">{{trans('beneficiary.caregiver_phone')}}</label>
                    <input class="form-control" :class="{ 'is-invalid': beneficiariesForm.errors.has('caregiver_phone') }" type="text" value="" v-model="beneficiariesForm.caregiver_phone" name="caregiver_phone" :placeholder="trans('beneficiary.caregiver_phone')">
                    <show-error :form-name="beneficiariesForm" prop-name="caregiver_phone"></show-error>
                </div>
            </div>

            <div class="col-xl-4 col-md-6 col-12">
                <div class="form-group">
                    <label for="">{{trans('beneficiary.caregiver_gender')}}</label>
                    <select class="form-control" :class="{ 'is-invalid': beneficiariesForm.errors.has('caregiver_gender') }" name="caregiver_gender" v-model="beneficiariesForm.caregiver_gender">
                        <option value="">{{trans('beneficiary.caregiver_gender')}}</option>
                        <option value="Male">Male</option>
                        <option value="Female" selected>Female</option>
                    </select>
                    <show-error :form-name="beneficiariesForm" prop-name="caregiver_gender"></show-error>
                </div>
            </div>

            <div class="col-xl-6 col-md-6 col-12">
                <div class="form-group">
                    <label for="">{{trans('beneficiary.caregiver_date_of_birth')}}</label>
                    <datepicker v-model="beneficiariesForm.caregiver_date_of_birth" :bootstrapStyling="true" @selected="beneficiariesForm.errors.clear('caregiver_date_of_birth')"  :class="{ 'form-control is-invalid': beneficiariesForm.errors.has('caregiver_date_of_birth') }" :placeholder="trans('beneficiary.caregiver_date_of_birth')"></datepicker>
                    <show-error :form-name="beneficiariesForm" prop-name="caregiver_date_of_birth"></show-error>
                </div>
            </div>

            <div class="col-xl-6 col-md-6 col-12">
                <div class="form-group">
                    <label for="">{{trans('beneficiary.caregiver_age')}}</label>
                    <input class="form-control" :class="{ 'is-invalid': beneficiariesForm.errors.has('caregiver_age') }" type="text" value="" v-model="beneficiariesForm.caregiver_age" name="caregiver_age" :placeholder="trans('beneficiary.caregiver_age')">
                    <show-error :form-name="beneficiariesForm" prop-name="caregiver_age"></show-error>
                </div>
            </div>

            <div class="col-xl-4 col-md-6 col-12">
                <div class="form-group">
                    <label for="">{{trans('beneficiary.caregiver_education_status')}}</label>
                    <select class="form-control" :class="{ 'is-invalid': beneficiariesForm.errors.has('caregiver_education_status') }" name="caregiver_education_status" v-model="beneficiariesForm.caregiver_education_status">
                        <option value="">{{trans('beneficiary.caregiver_education_status')}}</option>
                        <option value="NONE" selected>NONE</option>
                        <option value="PRIMARY">PRIMARY</option>
                        <option value="JSS">JSS</option>
                        <option value="SSCE">SSCE</option>
                        <option value="NCE">NCE</option>
                        <option value="OND">OND</option>
                        <option value="HND">HND</option>
                        <option value="BACHELOR">BACHELOR</option>
                        <option value="OTHER">OTHER</option>
                    </select>
                    <show-error :form-name="beneficiariesForm" prop-name="caregiver_education_status"></show-error>
                </div>
            </div>

            <div class="col-xl-4 col-md-6 col-12">
                <div class="form-group">
                    <label for="">{{trans('beneficiary.caregiver_employment_status')}}</label>
                    <select class="form-control" :class="{ 'is-invalid': beneficiariesForm.errors.has('caregiver_employment_status') }" name="caregiver_employment_status" v-model="beneficiariesForm.caregiver_employment_status">
                        <option value="">{{trans('beneficiary.caregiver_employment_status')}}</option>
                        <option value="NONE" selected>NONE</option>
                        <option value="Employee (Government)">Employee (Government)</option>
                        <option value="Housewife">Housewife</option>
                        <option value="Self-Employed">Self-Employed</option>
                        <option value="Dependant">Dependant</option>
                        <option value="Employee (Private Sector Ngo)">Employee (Private Sector Ngo)</option>
                        <option value="Pensioner">Pensioner</option>
                        <option value="Self-Employed (Nonagric)">Self-Employed (Nonagric)</option>
                        <option value="Employer">Employer</option>
                        <option value="Domestic Employee (House Help)">Domestic Employee (House Help)</option>
                        <option value="Pupil Student">Pupil Student</option>
                        <option value="Apprentice Intern">Apprentice Intern</option>
                    </select>
                    <show-error :form-name="beneficiariesForm" prop-name="caregiver_employment_status"></show-error>
                </div>
            </div>

            <div class="col-xl-4 col-md-6 col-12">
                <div class="form-group">
                    <label for="">{{trans('beneficiary.caregiver_relationship_status')}}</label>
                    <select class="form-control" :class="{ 'is-invalid': beneficiariesForm.errors.has('caregiver_relationship_status') }" name="caregiver_relationship_status" v-model="beneficiariesForm.caregiver_relationship_status">
                        <option value="">{{trans('beneficiary.caregiver_relationship_status')}}</option>
                        <option value="Child (Don Daughter)" selected>Child (Don Daughter)</option>
                        <option value="Niece Nephew">Niece Nephew</option>
                        <option value="Grandchild">Grandchild</option>
                        <option value="Other Relationship">Other Relationship</option>
                        <option value="Non Relative">Non Relative</option>
                        <option value="Adopted Foster Step Child">Adopted Foster Step Child</option>
                        <option value="Parent Parent In Law">Parent Parent In Law</option>
                        <option value="House Help">House Help</option>
                        <option value="Don Daughter In Law">Don Daughter In Law</option>
                    </select>
                    <show-error :form-name="beneficiariesForm" prop-name="caregiver_relationship_status"></show-error>
                </div>
            </div>

        
            <div class="col-xl-12 col-md-6 col-12">
                <div class="form-group">
                    <label for="">{{trans('beneficiary.school')}}</label>
                    <select class="form-control" :class="{ 'is-invalid': beneficiariesForm.errors.has('school_id') }" name="school_id" v-model="beneficiariesForm.school_id">
                        <option value="">{{trans('beneficiary.school')}}</option>
                        <option v-for="school in schools" :value="school.id">{{school.name}}</option>
                    </select>
                    <show-error :form-name="beneficiariesForm" prop-name="school_id"></show-error>
                </div>
            </div>

            
        </div>                              
        </div>
        <div class="row">
            <div class="col-md-8 offset-md-4">
                <button type="submit" class="btn btn-primary mr-1 mb-1 waves-effect waves-light pull-right">
                <span v-if="id && !enrollment">{{trans('general.update')}}</span>
                <span v-else-if="enrollment === 'enrollment'">{{trans('beneficiary.enroll')}}</span>
                <span v-else>{{trans('general.save')}}</span>
                </button>

                <a href="#" @click="$router.go(-1)" class="btn btn-danger waves-effect waves-light pull-right mr-1 mb-1">{{trans('general.cancel')}}
                </a>

            </div>
        </div>
    </form>
</template>


<script>
    import datepicker from 'vuejs-datepicker'

    export default {
        components: {datepicker},
        data() {
            return {
                beneficiariesForm: new Form({
                    id: '',
                    cohort_id: '',
                    state_id: '',
                    lga_id: '',
                    community: '',
                    admission_number: '',
                    name: '',
                    gender: 'Female',
                    class: '',
                    date_of_birth: '',
                    age: '',
                    disability: '',
                    caregiver_name: '',
                    caregiver_phone: '',
                    caregiver_gender: '',
                    caregiver_date_of_birth: '',
                    caregiver_age: '',
                    caregiver_employment_status: '',
                    caregiver_education_status: '',
                    caregiver_relationship_status: '',
                    school_id: '',
                    distance_from_school: '',
                    have_text_book: '',
                    have_uniform: '',
                    have_writing_material: '',
                    have_school_bag: '',
                    is_enrollment: false,
                }),
                cohorts: [],
                schools: [],
                states: [],
                lgas: []
            };
        },
        props: ['id', 'enrollment'],
        mounted() {
            if(this.id)
                this.getBeneficiary();
            this.fetchPreRequisites();
            if(this.enrollment === 'enrollment'){
                this.beneficiariesForm.is_enrollment = true;
            }
        },
        methods: {
            fetchPreRequisites(){
                axios.get('/api/user/pre-requisite')
                    .then(response => response.data)
                    .then(response => {
                        this.cohorts = response.cohorts;
                        this.schools = response.schools;
                        this.states = response.states;
                        this.lgas = response.lgas;
                    })
                    .catch(error => {
                        helper.showDataErrorMsg(error)
                    });
            },
            proceed(){
                if(this.id)
                    this.updateBeneficiary();
                else
                    this.storeBeneficiary();
            },
            storeBeneficiary(){
                this.beneficiariesForm.date_of_birth = moment(this.beneficiariesForm.date_of_birth).format('YYYY-MM-DD');
                this.beneficiariesForm.post('/api/beneficiaries')
                    .then(response => {
                        toastr.success(response.message);
                        this.$router.push('/beneficiaries');
                    })
                    .catch(error => {
                        toastr.error(error.message);
                        helper.showErrorMsg(error);
                    });
            },
            getBeneficiary(){
                axios.get('/api/beneficiaries/'+this.id)
                    .then(response => response.data)
                    .then(response => {
                        this.beneficiariesForm.id = response.beneficiary.id;
                        this.beneficiariesForm.cohort_id = response.beneficiary.cohort_id;
                        this.beneficiariesForm.state_id = response.beneficiary.state_id;
                        this.beneficiariesForm.lga_id = response.beneficiary.lga_id;
                        this.beneficiariesForm.community = response.beneficiary.community;
                        this.beneficiariesForm.admission_number = response.beneficiary.admission_number;
                        this.beneficiariesForm.name = response.beneficiary.name;
                        this.beneficiariesForm.email = response.beneficiary.email;
                        this.beneficiariesForm.date_of_birth = response.beneficiary.date_of_birth;
                        this.beneficiariesForm.class = response.beneficiary.class;
                        this.beneficiariesForm.caregiver_name = response.beneficiary.caregiver_name;
                        this.beneficiariesForm.caregiver_phone = response.beneficiary.caregiver_phone;
                        this.beneficiariesForm.caregiver_gender = response.beneficiary.caregiver_gender;
                        this.beneficiariesForm.caregiver_age = response.beneficiary.caregiver_age;
                        this.beneficiariesForm.caregiver_date_of_birth = response.beneficiary.caregiver_date_of_birth;
                        this.beneficiariesForm.caregiver_employment_status = response.beneficiary.caregiver_employment_status;
                        this.beneficiariesForm.caregiver_education_status = response.beneficiary.caregiver_education_status;
                        this.beneficiariesForm.caregiver_relationship_status = response.beneficiary.caregiver_relationship_status;
                        this.beneficiariesForm.gender = response.beneficiary.gender;
                        this.beneficiariesForm.age = response.beneficiary.age;
                        this.beneficiariesForm.disability = response.beneficiary.disability;
                        this.beneficiariesForm.voters_card = response.beneficiary.voters_card;
                        this.beneficiariesForm.school_id = response.beneficiary.school_id;
                        this.beneficiariesForm.distance_from_school = response.beneficiary.distance_from_school;
                        this.beneficiariesForm.have_text_book = response.beneficiary.have_text_book;
                        this.beneficiariesForm.have_uniform = response.beneficiary.have_uniform;
                        this.beneficiariesForm.have_writing_material = response.beneficiary.have_writing_material;
                        this.beneficiariesForm.have_school_bag = response.beneficiary.have_school_bag;

                    })
                    .catch(error => {
                        helper.showDataErrorMsg(error);
                        this.$router.push('/beneficiaries');
                    });
            },
            updateBeneficiary(){
                this.beneficiariesForm.date_of_birth = moment(this.beneficiariesForm.date_of_birth).format('YYYY-MM-DD');
                this.beneficiariesForm.patch('/api/beneficiaries/'+this.id)
                    .then(response => {
                        toastr.success(response.message);
                        if(this.enrollment === 'enrollment'){
                            this.$router.go(-1);
                        }else{
                            this.$router.push('/beneficiaries');
                        }
                        
                    })
                    .catch(error => {
                        toastr.error(error.message);
                        helper.showErrorMsg(error);
                    });
            }
           
        }
    }
</script>
