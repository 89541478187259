<template>
	<ul class="navigation navigation-main" id="main-menu-navigation" data-menu="menu-navigation">
	    <li :class="[currentPage.includes('home') ? activeClass : '', 'nav-item']">
	    	<router-link to="/home" exact><i class="feather icon-home"></i> <span class="menu-title">{{trans('general.home')}}</span>
	    	</router-link>
	    </li>

	    <li :class="[currentPage.includes('beneficiaries') ? activeClass : '']" class="nav-item has-sub">
            <a href="">
                <i class="feather icon-users"></i>
                <span class="menu-title" data-i18n="">Beneficiaries</span>
            </a>
            <ul class="menu-content" style="">
	    		<li v-if="hasPermission('upload-selected-beneficiaries')">
	    			<router-link to="/beneficiaries/import" exact>
	    				<i class="feather icon-list"></i>
	    				<span class="menu-title">
	    					Upload Selected
	    				</span>
	    			</router-link>
	    		</li>

	    		<li v-if="hasPermission('view-cohort-selected')">
	    			<router-link to="/beneficiaries/cohort" exact>
	    				<i class="feather icon-list"></i>
	    				<span class="menu-title">
	    					Cohort Selected
	    				</span>
	    			</router-link>
	    		</li>

	    		<li v-if="hasPermission('view-enrolled-list')">
	    			<router-link to="/beneficiaries/enrolled" exact>
	    				<i class="feather icon-list"></i>
	    				<span class="menu-title">
	    					Enrolled List
	    				</span>
	    			</router-link>
	    		</li>

	    		<li v-if="hasPermission('view-bank-accounts')">
	    			<router-link to="/beneficiaries/bank-accounts" exact>
	    				<i class="feather icon-list"></i>
	    				<span class="menu-title">
	    					Bank Accounts
	    				</span>
	    			</router-link>
	    		</li>

		    </ul>                                            
		</li>

		<li :class="[currentPage.includes('attendance') ? activeClass : '', 'nav-item']" v-if="hasPermission('view-attendance')">
	    	<router-link to="/attendances" exact>
	    		<i class="feather icon-clock"></i> 
	    		<span class="menu-title">
	    			Attendance
	    		</span>
	    	</router-link>
	    </li>

		<li class="nav-item has-sub" v-if="hasPermission('list-payments')">
            <a href="">
                <i class="feather icon-dollar-sign"></i>
                <span class="menu-title" data-i18n="">Payments</span>
            </a>
            <ul class="menu-content" style="">
            	<li :class="[currentPage.includes('payments') ? activeClass : '']" v-if="hasPermission('list-payments')">
	    			<router-link to="/payments" exact>
	    				<i class="feather icon-list"></i>
	    				<span class="menu-title">
	    					Payments
	    				</span>
	    			</router-link>
	    		</li>

	    		

	    		<li :class="[currentPage.includes('payment-schedule') ? activeClass : '']" v-if="hasPermission('create-payment-schedule')">
	            	<router-link to="/setting/payment-schedule" exact>
	            		<i class="feather icon-list"></i>
	            		<span class="menu-title">
	            			Payment Schedules
	            		</span>
	            	</router-link>
	    		</li>
		    </ul>                                            
		</li>

	    <li :class="[currentPage.includes('schools') ? activeClass : '', 'nav-item']" v-if="hasPermission('list-schools')">
	    	<router-link to="/schools" exact>
	    		<i class="feather icon-database"></i> 
	    		<span class="menu-title">
	    			Schools
	    		</span>
	    	</router-link>
	    </li>

	    <li :class="[currentPage.includes('user') ? activeClass : '', 'nav-item']" v-if="hasPermission('list-user') && getConfig('show_user_menu')">
			<router-link to="/user" exact>
				<i class="feather icon-user-plus"></i> 
				<span class="menu-title">
					Users
				</span>
			</router-link>
		</li>

		<li :class="[currentPage.includes('reports') ? activeClass : '', 'nav-item']" v-if="hasPermission('access-report')">
	    	<router-link to="/reports" exact>
	    		<i class="feather icon-trending-up"></i> 
	    		<span class="menu-title">
	    			Reports
	    		</span>
	    	</router-link>
	    </li>

	    <li class="nav-item has-sub" v-if="hasPermission('access-settings')">
            <a href="">
                <i class="feather icon-settings"></i>
                <span class="menu-title" data-i18n="">Settings</span>
            </a>
            <ul class="menu-content" style="">
            	<li :class="[currentPage.includes('cohort') ? activeClass : '']">
	    			<router-link to="/setting/cohort" exact>
	    				<i class="feather icon-list"></i>
	    				<span class="menu-title">
	    					Cohort
	    				</span>
	    			</router-link>
	    		</li>

	    		<li :class="[currentPage.includes('setting/cycle') ? activeClass : '']">
	            	<router-link to="/setting/cycle" exact>
	            		<i class="feather icon-list"></i>
	            		<span class="menu-title">
	            			Cycle
	            		</span>
	            	</router-link>
	    		</li>

	    		<li :class="[currentPage.includes('setting/trench') ? activeClass : '']">
	            	<router-link to="/setting/trench" exact>
	            		<i class="feather icon-list"></i>
	            		<span class="menu-title">
	            			Trench
	            		</span>
	            	</router-link>
	    		</li>

	    		<li :class="[currentPage.includes('setting/session') ? activeClass : '']">
	            	<router-link to="/setting/session" exact>
	            		<i class="feather icon-list"></i>
	            		<span class="menu-title">
	            			Session
	            		</span>
	            	</router-link>
	    		</li>

	    		<li :class="[currentPage.includes('setting/term') ? activeClass : '']">
	            	<router-link to="/setting/term" exact>
	            		<i class="feather icon-list"></i>
	            		<span class="menu-title">
	            			Term
	            		</span>
	            	</router-link>
	    		</li>

            	<li :class="[currentPage.includes('lga') ? activeClass : '']">
	    			<router-link to="/setting/lga" exact>
	    				<i class="feather icon-list"></i>
	    				<span class="menu-title">
	    					LGA
	    				</span>
	    			</router-link>
	    		</li>
		    </ul>                                            
		</li>

	    <li :class="[currentPage.includes('todo') ? activeClass : '', 'nav-item']" v-if="hasPermission('access-todo') && getConfig('show_todo_menu')">
	    	<router-link to="/todo" exact>
	    		<i class="feather icon-check-circle"></i> 
	    		<span class="menu-title">
	    			{{trans('todo.todo')}}
	    		</span>
	    	</router-link>
	    </li>
	    <li :class="[currentPage.includes('message') ? activeClass : '', 'nav-item']" v-if="hasPermission('access-message') && getConfig('show_message_menu')">
	    	<router-link to="/message" exact>
	    		<i class="feather icon-mail"></i> 
	    		<span class="menu-title">
	    			{{trans('message.message')}}
	    		</span>
	    	</router-link>
	    </li>

	    <li :class="[currentPage.includes('configuration') ? activeClass : '', 'nav-item']" v-if="hasPermission('access-sms') && getConfig('show_sms_menu')">
	    	<router-link to="/configuration/sms" exact>
	    		<i class="feather icon-message-circle"></i> 
	    		<span class="menu-title">
	    			{{trans('general.sms')}}
	    		</span>
	    	</router-link>
	    </li>

	   <li class="nav-item has-sub" v-if="hasPermission('access-configuration') && getConfig('show_email_menu')">
	   		<a href="">
	   			<i class="feather icon-inbox"></i>
	   			<span class="menu-title" data-i18n="">
	   				Email
   				</span>
   			</a>
   			<ul class="menu-content" style="">
   				<li :class="[currentPage.includes('email-template') ? activeClass : '']" v-if="hasPermission('access-configuration') && getConfig('show_email_template_menu')">
    				<router-link to="/email-template" exact>
			    		<i class="feather icon-mail"></i> 
			    		<span class="menu-title">
			    			{{trans('template.email_template')}}
			    		</span>
			    	</router-link>
	    		</li>
			    <li :class="[currentPage.includes('email-log') ? activeClass : '']" v-if="hasPermission('access-configuration') && getConfig('show_email_log_menu')">
			    	<router-link to="/email-log" exact>
			    		<i class="feather icon-mail"></i> 
			    		<span class="menu-title">
			    			{{trans('mail.email_log')}}
			    		</span>
			    	</router-link>
			    </li>
   			</ul>
	   </li>

	   <li class="nav-item has-sub" v-if="hasPermission('access-configuration') && getConfig('show_settings_menu')">
            <a href="">
                <i class="feather icon-settings"></i>
                <span class="menu-title" data-i18n="">System Settings</span>
            </a>
            <ul class="menu-content" style="">
	        	<li :class="[currentPage.includes('basic') ? activeClass : '']">
	        		<router-link to="/configuration/basic" exact>
	        			<i class="feather icon-settings"></i> 
	        			<span class="menu-title">
	        				{{trans('configuration.basic_configuration')}}
	        			</span>
	        		</router-link> 
	            </li>

	            <li :class="[currentPage.includes('logo') ? activeClass : '']">
	            	<router-link to="/configuration/logo" exact>
	            		<i class="feather icon-image"></i> 
	            		<span class="menu-title">
	            			{{trans('general.logo')}}
	            		</span>
	            	</router-link>
	    		</li>
	    		<li :class="[currentPage.includes('system') ? activeClass : '']">
	    			<router-link to="/configuration/system" exact>
	    				<i class="feather icon-settings"></i> 
	    				<span class="menu-title">
	    					{{trans('configuration.system_configuration')}}
	    				</span>
	    			</router-link>
	    		</li>
	    		<li :class="[currentPage.includes('mail') ? activeClass : '']">
	    			<router-link to="/configuration/mail" exact>
	    				<i class="feather icon-mail"></i> 
	    				<span class="menu-title">
	    					{{trans('mail.mail_configuration')}}
	    				</span>
	    			</router-link>
	    		</li>
	    		<li :class="[currentPage.includes('locale') ? activeClass : '']" v-if="getConfig('multilingual')">
	    			<router-link to="/configuration/locale" exact>
	    				<i class="fas fa-globe"></i> 
	    				<span class="menu-title">
	    					{{trans('locale.locale')}}
	    				</span>
	    			</router-link>
	    		</li>
	    		<li :class="[currentPage.includes('menu') ? activeClass : '']">
	    			<router-link to="/configuration/menu" exact>
	    				<i class="feather icon-list"></i> 
	    				<span class="menu-title">
	    					{{trans('general.menu')}}
	    				</span>
	    			</router-link>
	    		</li>
	        	<li :class="[currentPage.includes('authentication') ? activeClass : '']">
	        		<router-link to="/configuration/authentication" exact>
	        			<i class="feather icon-log-in"></i> 
	        			<span class="menu-title">
	        				{{trans('auth.authentication')}}
	        			</span>
	        		</router-link>
	    		</li>
	            
		    </ul>                                            
		</li>

		<li class="nav-item has-sub" v-if="hasPermission('access-configuration') && getConfig('show_permission_menu')">
            <a href="">
                <i class="feather icon-lock"></i>
                <span class="menu-title" data-i18n="">Permissions</span>
            </a>
            <ul class="menu-content" style="">
	        	<li :class="[currentPage.includes('role') ? activeClass : '']">
	        		<router-link to="/configuration/role" exact>
	        			<i class="feather icon-log-in"></i> 
	        			<span class="menu-title">
	        				{{trans('role.role')}}
	        			</span>
	        		</router-link>
	        	</li>
	    		<li :class="[currentPage.includes('permission') ? activeClass : '']">
	    			<router-link to="/configuration/permission" exact>
	    				<i class="feather icon-lock"></i> 
	    				<span class="menu-title">
	    					{{trans('permission.permission')}}
	    				</span>
	    			</router-link>
	    		</li>
	            
		    </ul>                                            
		</li>
	    
	    <li :class="[currentPage.includes('activity-log') ? activeClass : '', 'nav-item']" v-if="hasPermission('access-configuration') && getConfig('show_activity_log_menu')">
	    	<router-link to="/activity-log" exact>
	    		<i class="feather icon-activity"></i> 
	    		<span class="menu-title">
	    			{{trans('activity.activity_log')}}
	    		</span>
	    	</router-link>
	    </li>
	    <li :class="[currentPage.includes('ip-filter') ? activeClass : '', 'nav-item']" v-if="hasPermission('access-configuration') && getConfig('ip_filter')">
	    	<router-link to="/configuration/ip-filter" exact>
	    		<i class="feather icon-filter"></i> 
	    		<span class="menu-title">
	    			{{trans('ip_filter.ip_filter')}}
	    		</span>
	    	</router-link>
	    </li>
	    <li :class="[currentPage.includes('scheduled-job') ? activeClass : '', 'nav-item']" v-if="hasPermission('access-configuration') && getConfig('show_schedule_job_menu')">
	    	<router-link to="/configuration/scheduled-job" exact>
	    		<i class="feather icon-clock"></i> 
	    		<span class="menu-title">
	    			{{trans('general.scheduled_job')}}
	    		</span>
	    	</router-link>
	    </li>
	    <li :class="[currentPage.includes('backup') ? activeClass : '', 'nav-item']" v-if="hasPermission('access-configuration') && getConfig('show_backup_menu')">
	    	<router-link to="/backup" exact>
	    		<i class="feather icon-box"></i> 
	    		<span class="menu-title">
	    			{{trans('backup.backup')}}
	    		</span>
	    	</router-link>
	    </li>

	        
	    
    </ul>
</template>

<script>
	export default {
		data() {
			return {
				activeClass: 'active'
			}
		},
		methods: {
			hasPermission(permission){
				return helper.hasPermission(permission);
			},
			hasRole(role){
				return helper.hasRole(role);
			},
			getConfig(config){
				return helper.getConfig(config);
			}
		},

		computed: {
			currentPage(){
				return this.$route.path;
			}
		}
	}
</script>
