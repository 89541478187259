<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-8 col-12 mb-2">
                <div class="row breadcrumbs-top">
                    <div class="col-12">
                        <h2 class="content-header-title float-left mb-0">{{trans('permission.permission')}}
                            <span class="card-subtitle" v-if="permissions">{{trans('general.total_result_found',{'count' : permissions.total})}}</span>
                            <span class="card-subtitle" v-else>{{trans('general.no_result_found')}}</span>
                        </h2>
                        <div class="breadcrumb-wrapper col-12">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item">
                                    <a href="/">Home</a>
                                </li>
                                <li class="breadcrumb-item active">{{trans('permission.permission')}}</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content-header-right text-md-right col-md-4 col-12 d-md-block d-none">
                <div class="form-group breadcrum-right">
                    <div class="dropdown">
                    <button class="btn-icon btn btn-primary btn-round btn-sm dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="feather icon-settings"></i></button>
                    <div class="dropdown-menu dropdown-menu-right">
                        <a class="dropdown-item" href="#">
                            <button class="btn btn-primary btn-sm pull-right m-r-10" @click="showCreatePanel = !showCreatePanel"><i class="feather icon-plus"></i> {{trans('permission.add_new_permission')}}</button>
                        </a>
                        <a class="dropdown-item" href="#">
                            <button class="btn btn-primary btn-sm pull-right m-r-10" @click="$router.push('/configuration/permission/assign')"><i class="feather icon-user-plus"></i> <span class="d-none d-sm-inline">{{trans('permission.assign_permission')}}</span></button>
                        </a>
                    </div>
                </div>
                    
                </div>
            </div>

        </div>

        <div class="content-body">
            <section>
                <div class="row">
                    <div class="col-md-12">
                        <transition name="fade">
                            <div class="card"  v-if="showCreatePanel">
                            <div class="card-header">
                                <h4 class="card-title">{{trans('permission.add_new_permission')}}</h4>
                            </div>
                            <div class="card-content">
                                <div class="card-body">
                                    <permission-form @completed="getPermissions" @cancel="showCreatePanel = !showCreatePanel"></permission-form>
                                </div>
                            </div>
                        </div>
                        </transition>
                    </div>
                </div>
            </section>

            <section>
                <div class="row">
                    <div class="col-md-12">
                        <div class="card">
                        
                        <div class="card-content">
                            <div class="card-body">
                                <div class="table-responsive" v-if="permissions.total">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th>{{trans('permission.name')}}</th>
                                            <th class="table-option">{{trans('general.action')}}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="permission in permissions.data">
                                            <td v-text="toWord(permission.name)"></td>
                                            <td class="table-option">
                                                <div class="btn-group">
                                                    <button class="btn btn-danger btn-sm" :key="permission.id" v-confirm="{ok: confirmDelete(permission)}" v-tooltip="trans('permission.delete_permission')"><i class="feather icon-trash-2"></i></button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <module-info v-if="!permissions.total" module="permission" title="module_info_title" description="module_info_description" icon="lock"></module-info>
                            <pagination-record :page-length.sync="filterPermissionForm.page_length" :records="permissions" @updateRecords="getPermissions" @change.native="getPermissions"></pagination-record>
                             
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </section>

            
        </div>

        
    </div>
</template>

<script>
    import permissionForm from './form'

    export default {
        components : { permissionForm },
        data() {
            return {
                permissions: {
                    total: 0,
                    data: []
                },
                filterPermissionForm: {
                    page_length: helper.getConfig('page_length')
                },
                showCreatePanel: false
            };
        },
        mounted(){
            if(!helper.hasPermission('access-configuration')){
                helper.notAccessibleMsg();
                this.$router.push('/home');
            }
            this.getPermissions();
        },
        methods: {
            getPermissions(page){
                if (typeof page !== 'number') {
                    page = 1;
                }
                let url = helper.getFilterURL(this.filterPermissionForm);
                axios.get('/api/permission?page=' + page + url)
                    .then(response => response.data)
                    .then(response => this.permissions = response)
                    .catch(error => {
                        helper.showDataErrorMsg(error);
                    });
            },
            confirmDelete(permission){
                return dialog => this.deletePermission(permission);
            },
            deletePermission(permission){
                axios.delete('/api/permission/'+permission.id)
                    .then(response => response.data)
                    .then(response => {
                        toastr.success(response.message);
                        this.getPermissions();
                    }).catch(error => {
                        helper.showDataErrorMsg(error);
                    });
            },
            toWord(str){
                return helper.toWord(str);
            }
        }
    }
</script>
