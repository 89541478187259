var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "content-body" }, [
      _vm.userRole == "admin"
        ? _c("h2", { staticClass: "content-header-title" }, [
            _vm._v("All States"),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("section", { attrs: { id: "dashboard-ecommerce" } }, [
        _vm.userRole == "admin"
          ? _c(
              "div",
              { staticClass: "row" },
              _vm._l(_vm.activeStates, function (activeState) {
                return _c("div", { staticClass: "col-lg-3 col-sm-6 col-12" }, [
                  _c("div", { staticClass: "card" }, [
                    _c(
                      "div",
                      { staticClass: "card-header d-flex flex-column pb-0" },
                      [
                        _c(
                          "div",
                          { staticClass: "avatar bg-rgba-primary p-50 m-0" },
                          [
                            _c("div", { staticClass: "avatar-content" }, [
                              _c("img", {
                                attrs: {
                                  src: `images/${activeState.logo}`,
                                  width: "50",
                                  alt: "branding logo",
                                },
                              }),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "router-link",
                          {
                            staticClass: "primary",
                            attrs: {
                              to: `beneficiaries/state-graph/${activeState.id}`,
                            },
                          },
                          [
                            _c("p", { staticClass: "mb-1" }, [
                              _vm._v(_vm._s(activeState.name)),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ])
              }),
              0
            )
          : _vm._e(),
        _vm._v(" "),
        _c("h2", { staticClass: "content-header-title" }, [
          _vm._v("Statistics"),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-lg-4 col-sm-6 col-12" }, [
            _c("div", { staticClass: "card" }, [
              _c(
                "div",
                {
                  staticClass:
                    "card-header d-flex flex-column align-items-start pb-0",
                },
                [
                  _vm._m(1),
                  _vm._v(" "),
                  _c(
                    "router-link",
                    {
                      staticClass: "primary",
                      attrs: {
                        to: "/statistic/beneficiaries?type=selected&cohort=all",
                      },
                    },
                    [
                      _c("h2", { staticClass: "text-bold-700 mt-1" }, [
                        _vm._v(_vm._s(_vm.selected_beneficiaries)),
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "mb-0" }, [
                        _vm._v("Total Beneficiaries Selected"),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-lg-4 col-sm-6 col-12" }, [
            _c("div", { staticClass: "card" }, [
              _c(
                "div",
                {
                  staticClass:
                    "card-header d-flex flex-column align-items-start pb-0",
                },
                [
                  _vm._m(2),
                  _vm._v(" "),
                  _c(
                    "router-link",
                    {
                      staticClass: "success",
                      attrs: {
                        to: "/statistic/beneficiaries?type=enrolled&cohort=all",
                      },
                    },
                    [
                      _c("h2", { staticClass: "text-bold-700 mt-1" }, [
                        _vm._v(_vm._s(_vm.enrolled_beneficiaries)),
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "mb-0" }, [
                        _vm._v("Total Beneficiaries Enrolled"),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-lg-4 col-sm-6 col-12" }, [
            _c("div", { staticClass: "card" }, [
              _c(
                "div",
                {
                  staticClass:
                    "card-header d-flex flex-column align-items-start pb-0",
                },
                [
                  _vm._m(3),
                  _vm._v(" "),
                  _c(
                    "router-link",
                    {
                      staticClass: "success",
                      attrs: {
                        to: "/statistic/attendances?type=above&cohort=all&cycle=all&term=all",
                      },
                    },
                    [
                      _c("h2", { staticClass: "text-bold-700 mt-1" }, [
                        _vm._v(_vm._s(_vm.attendance_above_70)),
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "mb-0" }, [
                        _vm._v("Beneficiaries Attendances (70% and above)"),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-lg-4 col-sm-6 col-12" }, [
            _c("div", { staticClass: "card" }, [
              _c(
                "div",
                {
                  staticClass:
                    "card-header d-flex flex-column align-items-start pb-0",
                },
                [
                  _vm._m(4),
                  _vm._v(" "),
                  _c(
                    "router-link",
                    {
                      staticClass: "danger",
                      attrs: {
                        to: "/statistic/attendances?type=below&cohort=all&cycle=all&term=all",
                      },
                    },
                    [
                      _c("h2", { staticClass: "text-bold-700 mt-1" }, [
                        _vm._v(_vm._s(_vm.attendance_below_70)),
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "mb-0" }, [
                        _vm._v("Beneficiaries Attendances (Below 70%)"),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-lg-4 col-sm-6 col-12" }, [
            _c("div", { staticClass: "card" }, [
              _c(
                "div",
                {
                  staticClass:
                    "card-header d-flex flex-column align-items-start pb-0",
                },
                [
                  _vm._m(5),
                  _vm._v(" "),
                  _c(
                    "router-link",
                    {
                      staticClass: "success",
                      attrs: {
                        to: "/statistic/payments?type=paid&cohort=all&cycle=all&trench=all",
                      },
                    },
                    [
                      _c("h2", { staticClass: "text-bold-700 mt-1" }, [
                        _vm._v(_vm._s(_vm.paid_beneficiaries)),
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "mb-0" }, [
                        _vm._v("Total Beneficiaires Paid"),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-lg-4 col-sm-6 col-12" }, [
            _c("div", { staticClass: "card" }, [
              _c(
                "div",
                {
                  staticClass:
                    "card-header d-flex flex-column align-items-start pb-0",
                },
                [
                  _vm._m(6),
                  _vm._v(" "),
                  _c(
                    "router-link",
                    {
                      staticClass: "warning",
                      attrs: {
                        to: "/statistic/payments?type=not_paid&cohort=all&cycle=all&trench=all",
                      },
                    },
                    [
                      _c("h2", { staticClass: "text-bold-700 mt-1" }, [
                        _vm._v(_vm._s(_vm.not_paid_beneficiaries)),
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "mb-0" }, [
                        _vm._v("Total Beneficiaries Not Paid"),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _vm.userRole == "admin" || _vm.userRole == "state cct"
          ? _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-lg-12 col-md-6 col-12" }, [
                _c("div", { staticClass: "card" }, [
                  _vm._m(7),
                  _vm._v(" "),
                  _c("div", { staticClass: "card-content" }, [
                    _c(
                      "div",
                      { staticClass: "card-body pb-0" },
                      [
                        _c("beneficiaryChart", {
                          attrs: {
                            data: _vm.getChartData,
                            label: _vm.getChartLabel,
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
            ])
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "content-header row" }, [
      _c("div", { staticClass: "content-header-left col-md-9 col-12 mb-2" }, [
        _c("div", { staticClass: "row breadcrumbs-top" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("h2", { staticClass: "content-header-title float-left mb-0" }, [
              _vm._v("Home"),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "breadcrumb-wrapper col-12" }, [
              _c("ol", { staticClass: "breadcrumb" }, [
                _c("li", { staticClass: "breadcrumb-item" }, [
                  _c("a", { attrs: { href: "/" } }, [_vm._v("Dashboard")]),
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "breadcrumb-item active" }, [
                  _vm._v(
                    "\n                                 Home\n                             "
                  ),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "content-header-right text-md-right col-md-3 col-12 d-md-block d-none",
        },
        [
          _c("div", { staticClass: "form-group breadcrum-right" }, [
            _c("div", { staticClass: "dropdown" }, [
              _c(
                "button",
                {
                  staticClass:
                    "btn-icon btn btn-primary btn-round btn-sm dropdown-toggle",
                  attrs: {
                    type: "button",
                    "data-toggle": "dropdown",
                    "aria-haspopup": "true",
                    "aria-expanded": "false",
                  },
                },
                [_c("i", { staticClass: "feather icon-settings" })]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "dropdown-menu dropdown-menu-right" }, [
                _c(
                  "a",
                  { staticClass: "dropdown-item", attrs: { href: "#" } },
                  [_vm._v("Chat")]
                ),
                _c(
                  "a",
                  { staticClass: "dropdown-item", attrs: { href: "#" } },
                  [_vm._v("Email")]
                ),
                _c(
                  "a",
                  { staticClass: "dropdown-item", attrs: { href: "#" } },
                  [_vm._v("Calendar")]
                ),
              ]),
            ]),
          ]),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "avatar bg-rgba-primary p-50 m-0" }, [
      _c("div", { staticClass: "avatar-content" }, [
        _c("i", {
          staticClass: "feather icon-users text-primary font-medium-5",
        }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "avatar bg-rgba-success p-50 m-0" }, [
      _c("div", { staticClass: "avatar-content" }, [
        _c("i", {
          staticClass: "feather icon-users text-success font-medium-5",
        }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "avatar bg-rgba-success p-50 m-0" }, [
      _c("div", { staticClass: "avatar-content" }, [
        _c("i", {
          staticClass: "feather icon-database text-success font-medium-5",
        }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "avatar bg-rgba-danger p-50 m-0" }, [
      _c("div", { staticClass: "avatar-content" }, [
        _c("i", {
          staticClass: "feather icon-database text-danger font-medium-5",
        }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "avatar bg-rgba-success p-50 m-0" }, [
      _c("div", { staticClass: "avatar-content" }, [
        _c("i", {
          staticClass: "feather icon-dollar-sign text-success font-medium-5",
        }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "avatar bg-rgba-warning p-50 m-0" }, [
      _c("div", { staticClass: "avatar-content" }, [
        _c("i", {
          staticClass: "feather icon-dollar-sign text-warning font-medium-5",
        }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "card-header d-flex justify-content-between align-items-end",
      },
      [
        _c("h4", { staticClass: "card-title" }, [
          _vm._v("Selected Beneficiaries"),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "font-medium-5 mb-0" }, [
          _c("i", {
            staticClass: "feather icon-settings text-muted cursor-pointer",
          }),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }